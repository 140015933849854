import React, { useEffect, useState } from 'react';
import { TextField } from '@fluentui/react/lib/TextField';
import { CommandBar, DatePicker, DefaultButton, Dropdown, FontWeights, IBasePickerSuggestionsProps, ICommandBarItemProps, IDropdownOption, IStackStyles, ITag, IconButton, Label, MessageBar, MessageBarType, PrimaryButton, Stack, TagPicker } from '@fluentui/react';
import { CoherencePanelSize } from '@coherence-design-system/utilities';
import { CoherenceNotificationType, CoherencePanel } from '@coherence-design-system/controls';
import { SearchableDropdown } from '../CustomSearchDropdown/CustomSearchDropdown';
import { getDataFromLocalStorage,  isEmpty, onFormatDate, storeDataInLocalStorage } from '../../utils/DOMEApiHelper';
import dayjs from 'dayjs';
import ToastMessage from '../ToastMessage/ToastMessage';

interface prop {
  filterColumns: any;
  sortColumns: any;
  data: any;
  onApplyFilter: any
  onClearFilter: any;
  onSorted: any;
  additionalControls?:any;
  onClickAdditionalControls?:any;
  defaultFilterSessionKey:any;
}

const CustomGridControls = (props: prop) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filteredValues, setFilteredValues] = useState<any>();
  const [appliedFilterValues, setAppliedFilterValues] = useState<any>();
  const [toastType, setToastType] = useState<CoherenceNotificationType>(CoherenceNotificationType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastTitle, setToastTitle] = useState<string>("");
  const [showToast, setShowToast] = React.useState<boolean>(false);
  useEffect(() => { 
    const { data, filterColumns } = props; 
    if(data && data.length > 0 ){
      if((isEmpty(filteredValues) || Object.keys(filteredValues).length == 0) && isEmpty(getDataFromLocalStorage(props.defaultFilterSessionKey))){ 
        const byDefaultFilter = filterColumns.filter((x:any) => x.Default != null);
        if(byDefaultFilter.length > 0){   
          let newFilterValues = { ...filteredValues};
          byDefaultFilter.forEach((element:any) => {
            newFilterValues = { ...newFilterValues, [element.fieldName]: element.Default };
          });  
          setFilteredValues(newFilterValues);
          setAppliedFilterValues(newFilterValues);
          onClickApplyFilter(newFilterValues);    
        }  
      }
      else{
        if(isEmpty(getDataFromLocalStorage(props.defaultFilterSessionKey))){
          onClickApplyFilter(filteredValues);
        }
        else{
          setFilteredValues(getDataFromLocalStorage(props.defaultFilterSessionKey));
          onClickApplyFilter(getDataFromLocalStorage(props.defaultFilterSessionKey));
        }
      }    
    } 
  }, [props.data]);

  const onClickApplyFilter = (inputFilteredValues:any) => {

    const filteredValuesObj = inputFilteredValues || { ...filteredValues }; 
    const hasFilterInputValue = Object.values(filteredValuesObj).some((value) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return value !== '' && value !== null;
    });
    if (!hasFilterInputValue) {
      if (inputFilteredValues != null) {
        props.onClearFilter();
        setIsFilterApplied(false);
      }
      return;
    }

    setIsFilterApplied(true);
    setIsPanelOpen(false);
    let filteredData: any = [];
    let filteredDataAssigned: boolean=false; 
    props.filterColumns.forEach((item: any) => {
      const fieldValue = filteredValuesObj[item.fieldName];
      switch (item.type) {
        case 'text':
          case 'dropdown':
          case 'searchDropdown':
            case 'searchTextBox':
            if (!isEmpty(fieldValue)) {
              //if filter is applied on login user as assigned to then filter the groups which the user is  memeber of.  
              if(item.fieldName== "AssignedToUserName" && fieldValue==sessionStorage.getItem("localAccountName")){
                filteredData = (filteredDataAssigned ? filteredData : props.data).filter((data:any) => data[item.fieldName]?.toString() === fieldValue || data.IsMemberOfGroup==true);
              }else{
                filteredData = (filteredDataAssigned ? filteredData : props.data).filter((data:any) => data[item.fieldName]?.toString() === fieldValue);
              }
              filteredDataAssigned = true;
            }
            break;
        case "multiselectDropdown":
          if (!isEmpty(fieldValue)) {
            var filteredDropdownData: any = [];
            fieldValue.forEach((value: any) => {
              filteredDropdownData = filteredDropdownData.concat((filteredDataAssigned ? filteredData : props.data).filter((data: any) => data[item.fieldName] === value));
            });
            filteredData = filteredDropdownData;
            filteredDataAssigned=true;
          }
          break;
        case "date":
          if (!isEmpty(filteredValuesObj["from" + item.fieldName])) {
            filteredData = (filteredDataAssigned? filteredData : props.data).filter((data: any) => {
              if (data[item.fieldName] !== null && data[item.fieldName] !== undefined && data[item.fieldName] !== "") {
                const fromDate = new Date(filteredValuesObj["from" + item.fieldName]);
                const dateValue = new Date(data[item.fieldName]);
                fromDate.setHours(0, 0, 0, 0);
                dateValue.setHours(0, 0, 0, 0);
                return dateValue >= fromDate;
              } else {
                return false;
              }
            });
            filteredDataAssigned=true;
          }
          if (!isEmpty(filteredValuesObj["to" + item.fieldName])) {
            filteredData = (filteredDataAssigned ? filteredData : props.data).filter((data: any) => {
              if (data[item.fieldName] !== null && data[item.fieldName] !== undefined && data[item.fieldName] !== "") {
                const toDate = new Date(filteredValuesObj["to" + item.fieldName]);
                const dateValue = new Date(data[item.fieldName]);
                toDate.setHours(23, 59, 59, 999);
                dateValue.setHours(23, 59, 59, 999);
                return dateValue <= toDate;
              } else {
                return false;
              }
            });
            filteredDataAssigned=true;
          }
          break;
          case 'contains':
            if (!isEmpty(fieldValue)) {
              var filteredDropdownData: any = [];
              fieldValue.forEach((value: any) => {
                filteredDropdownData = filteredDropdownData.concat((filteredDataAssigned ? filteredData : props.data).filter((data: any) => data[item.fieldName].includes(value)));
              });
              filteredData = filteredDropdownData;
              filteredDataAssigned=true;
            } 
          break;
      }
    });
    const uniqueArray = Array.from(new Set(filteredData.map((item: any) => item.Id))).map((Id: any) => {
      return filteredData.find((item: any) => item.Id === Id);
    });
    setAppliedFilterValues(filteredValuesObj);
    props.onApplyFilter(uniqueArray);
  }
  const onClickSaveUserPref = () => { 
    const filteredValuesObj = { ...filteredValues };   
    storeDataInLocalStorage(props.defaultFilterSessionKey, filteredValuesObj);
    setToastTitle("Filter Panel");
    setToastType(CoherenceNotificationType.SUCCESS);
    setToastMessage("Default filter saved successfully.");
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 3000);
  }

  const onClickClearFilter = () => {
    const newFilterValues = { ...filteredValues }; // create a copy of the filter values object
    props.filterColumns.forEach((element: any) => {
      if (element.type === 'dropdown' || element.type === 'searchDropdown' || element.type === 'searchTextBox') {
        newFilterValues[element.fieldName] = null; // set the value to empty array
      }
      if (element.type === 'multiselectDropdown' || element.type === 'contains') {
        newFilterValues[element.fieldName] = []; // set the value to empty array
      }
      else if (element.type === 'date') {
        newFilterValues["from" + element.fieldName] = null; // set the from date value to empty 
        newFilterValues["to" + element.fieldName] = null; // set the to date value to empty 
      }
      else {
        newFilterValues[element.fieldName] = ""; // set the value of each filter field to an empty string
      }
    });
    setFilteredValues(newFilterValues); // update the state of the filter values object
    setAppliedFilterValues(newFilterValues);
    props.onClearFilter();
    setIsFilterApplied(false);
  }

  function handleFromDateChangeFactory(object: any) {
    return function handleDropdownChange(selectedDate: any) {
      const newFilterValues = { ...filteredValues, ["from" + object.fieldName]: selectedDate };
      setFilteredValues(newFilterValues);
     
    }
  }
  function handleToDateChangeFactory(object: any) {
    return function handleDropdownChange(selectedDate: any) {
      const newFilterValues = { ...filteredValues, ["to" + object.fieldName]: selectedDate };
      setFilteredValues(newFilterValues);
      
    }
  }

  function handleDropdownChangeFactory(object: any) {
    return function handleDropdownChange(event: React.FormEvent<HTMLDivElement>, option: IDropdownOption | undefined) {
      if (!option) return;

      const newFilterValues = { ...filteredValues, [object.fieldName]: option.key };
      setFilteredValues(newFilterValues);
    }
  }

  function handleMultiSelectDropdownChangeFactory(object: any) {
    return function handleDropdownChange(event: React.FormEvent<HTMLDivElement>, option: IDropdownOption | undefined) {
      if (!option) return;
      let selectedValues:any =[];
       
      if(!isEmpty(filteredValues[object.fieldName])){
        selectedValues= [...filteredValues[object.fieldName]];
      }
      
      if (option.selected) {
        selectedValues.push(option.key);
      }
      else {
        selectedValues = selectedValues.filter((item: any) => item !== option.key);
      }

      const newFilterValues = { ...filteredValues, [object.fieldName]: selectedValues };
      setFilteredValues(newFilterValues);
    }
  }

  const generateControls = (): any => {
    let tmpRenderObj: any[] = [];
      if (showToast) {
      tmpRenderObj.push(
        <ToastMessage showToast={showToast} type={toastType} message={toastMessage} title={toastTitle}  ></ToastMessage>
      );
    }
    if (props.filterColumns === undefined)
      return;
    props.filterColumns.forEach((object: any) => {
      if ( object.type === "contains" || object.type === "multiselectDropdown" || object.type === "dropdown" || object.type === "searchDropdown" || object.type === "searchTextBox") {
        let options: any[] = [];
        if (object.type === "contains") {
          const optionSet = new Set();
          props.data.forEach((item: any) => {
            const fieldValues = item[object.fieldName]?.toString();
            if (fieldValues?.includes(',')) {
              fieldValues.split(',').forEach((value: string) => optionSet.add(value.trim()));
            } else {
              optionSet.add(fieldValues);
            }
          });
          options = Array.from(optionSet).map(value => ({ key: value, text: value }));
        } else {
          options = props.data.map((item: any) => ({
            key: item[object.fieldName]?.toString(),
            text: item[object.fieldName]?.toString()
          }));
        }
        options = options.filter((x: any) => x.key !== undefined && x.key !== null && x.key !== "");
        options = [...new Map(options.map((item: any) => [item["key"], item])).values()];
        object.options = [...options].sort((a, b) => {
          return a.key.localeCompare(b.key);
        }); 
      }
    });
    
    //search Textbox code start
const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: 'Suggested Result(s)', 
  noResultsFoundText:'No results found'
};

const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
  if (!tagList || !tagList.length || tagList.length === 0) {
    return false;
  }
  return tagList.some(compareTag => compareTag.key === tag.key);
};
 
const filterSuggestedTags = async (filterText: string, tagList?: ITag[] | undefined, object?: any): Promise<ITag[]> => {
  const suggestionsHeaderText = 'Suggested Result(s)';
  let minChar=0;
  let noResultsFoundText='No results found'; 
  if(!isEmpty(filterText) && !isEmpty(object.additionalData)){
    minChar=object.additionalData.minChar;
    noResultsFoundText = filterText.length >= minChar ? 'No results found' : 'Enter a minimum of '+minChar+' characters for result(s)';
  } 
  // Update pickerSuggestionsProps dynamically
  pickerSuggestionsProps.suggestionsHeaderText = suggestionsHeaderText;
  pickerSuggestionsProps.noResultsFoundText = noResultsFoundText;
  // Access 'object' and use it as needed 
  let result=[]; 
  if(!isEmpty(filterText)&& filterText.length >= minChar){
  result=  object.options.filter(
      (tag:any) => tag.text.toLowerCase().indexOf(filterText.toLowerCase()) === 0 && !listContainsTagList(tag, tagList),
    ).map((item:any) => ({ name: item.text })) 
  }
  return result;
}
//search Textbox code end

    const getTextFromItem = (item: ITag) => item.name; 
    props.filterColumns.forEach((object: any) => {
      switch (object.type) {
        case 'text':
          {
            tmpRenderObj.push(
              <TextField
                label={object.label}
                value={filteredValues[object.fieldName]}
                onChange={(event, newValue) => {
                  const newFilterValues = { ...filteredValues };
                  newFilterValues[object.fieldName] = newValue || "";
                  setFilteredValues(newFilterValues);
                }}
                />

            );
            break
          }
        case 'dropdown':
          {
            tmpRenderObj.push(
              <Dropdown
                label={object.label}
                options={object.options}
                placeholder={"Select " + object.label}
                required={false}
                selectedKey={filteredValues[object.fieldName]}
                onChange={handleDropdownChangeFactory(object)}
              />
            );
            break
          }
        case 'multiselectDropdown':
        case 'contains':
          {
            tmpRenderObj.push(
              <Dropdown
                label={object.label}
                options={object.options}
                placeholder={"Select " + object.label}
                required={false}
                multiSelect
                selectedKeys={filteredValues[object.fieldName]}
                onChange={handleMultiSelectDropdownChangeFactory(object)}
              />
            );
            break
          }
        case 'searchDropdown':
          {
            tmpRenderObj.push(
              <SearchableDropdown
                placeholder={"Select " + object.label}
                label={object.label}
                options={object.options}
                // styles={dropdownStyles}
                multiSelect={false}
                selectedKey={filteredValues[object.fieldName]}
                onChange={handleDropdownChangeFactory(object)}
              />
            );
            break
          }
        case 'date':
          {
            tmpRenderObj.push(
              <DatePicker
                allowTextInput={false}
                label={"From " + object.label}
                value={filteredValues["from" + object.fieldName] || null}
                placeholder={"Select From " + object.label}
                onSelectDate={handleFromDateChangeFactory(object)}
                formatDate={onFormatDate} 
              ></DatePicker>
            );
            tmpRenderObj.push(
              <DatePicker
                allowTextInput={false}
                value={filteredValues["to" + object.fieldName] || null}
                label={"To " + object.label}
                placeholder={"Select To " + object.label}
                minDate={filteredValues["from" + object.fieldName]}
                onSelectDate={handleToDateChangeFactory(object)}
                formatDate={onFormatDate} 
              ></DatePicker>
            );
            break
          }
          case 'searchTextBox':
            {
              tmpRenderObj.push(
              <div> 
              <Label>{object.label}</Label>
                     <TagPicker  
                    selectedItems={!isEmpty(filteredValues[object.fieldName]) ? [{ name: filteredValues[object.fieldName], key: filteredValues[object.fieldName] }]:[]}
                     removeButtonAriaLabel="Remove"
                     selectionAriaLabel={"Selected "+ (filteredValues!=undefined && filteredValues[object.fieldName])? filteredValues[object.fieldName]:''}
                     inputProps={{ 
                      'aria-label': 'Search by '+object.label,
                       placeholder:"Search by "+object.label
                   }}
                     onResolveSuggestions={(filterText, tagList) => filterSuggestedTags(filterText, tagList, object)}  
                     onChange={(items:any) => { 
                        if(items.length>0){  
                            const newFilterValues = { ...filteredValues, [object.fieldName]: items[0].name };
                            setFilteredValues(newFilterValues);
                           } 
                           else{
                            const newFilterValues = { ...filteredValues, [object.fieldName]: "" };
                            setFilteredValues(newFilterValues);
                           }
                        }  
                     }
                      getTextFromItem={getTextFromItem}
                     pickerSuggestionsProps={pickerSuggestionsProps}
                     itemLimit={1}
                     // this option tells the picker's callout to render inline instead of in a new layer
                     pickerCalloutProps={{ doNotLayer: true,calloutMaxWidth: 350 }} 
                   /> 
                     </div>
              );
              break
            }
      }
    });


    return (
      <div style={{ display: "grid", gridTemplateColumns: "50% 50%", gridGap: "1rem" }}>
        {tmpRenderObj}
      </div>
    );
  }
  const handleOnPanelClose = (): void => {
    // onClickClearFilter();
    setIsPanelOpen(false);
  };
  const onFilterPanelOpen = (): void => {
    if (filteredValues === undefined) {
      const defaultValues: { [key: string]: any } = {};
      props.filterColumns.forEach((object: any) => {
        if (object.type === "contains" || object.type === "multiselectDropdown" || object.type === "dropdown" || object.type === "searchDropdown" || object.type === "searchTextBox") {
          defaultValues[object.fieldName] = [];
        }
        else {
          defaultValues[object.fieldName] = "";
        }
      });
      setFilteredValues(defaultValues);
      }

      setIsPanelOpen(true);

    };

  //Sort code start
  const onSorted = (columName: string, sortyType: string): void => {
    props.onSorted(columName, sortyType);
  }
  //Sort code end
  
  const removeFilterFromUI = (propertyInput: any, fieldName: any) => {
    const newFilterValues = { ...filteredValues };
    const column = props.filterColumns.find((element: any) => element.fieldName === fieldName); 
    if (column) {
      if ((column.type === 'dropdown' || column.type === 'searchDropdown' || column.type === 'searchTextBox') && newFilterValues[propertyInput]) {
        newFilterValues[column.fieldName] = null;
      } else if ((column.type === 'multiselectDropdown' ||  column.type === 'contains') && newFilterValues[propertyInput]) {
        newFilterValues[column.fieldName] = [];
      } else if (column.type === 'date') {
        if (newFilterValues['from' + fieldName]) {
          newFilterValues['from' + column.fieldName] = null;
        } else if (newFilterValues['to' + fieldName]) {
          newFilterValues['to' + column.fieldName] = null;
        }
      } else {
        newFilterValues[column.fieldName] = '';
      }
    } 
    setFilteredValues(newFilterValues);
    setAppliedFilterValues(newFilterValues);
    onClickApplyFilter(newFilterValues);
  };
 
  const createCommandBarActiveFilter = (commandBarItems:any) => { 
    for (const prop in appliedFilterValues) {
      if ((Array.isArray(appliedFilterValues[prop]) && appliedFilterValues[prop].length >0) ||  ((!Array.isArray(appliedFilterValues[prop])) && appliedFilterValues[prop]!=='' && appliedFilterValues[prop]!==null)) {        
        let column=props.filterColumns.filter((x:any)=>x.fieldName==prop || "to"+x.fieldName==prop || "from"+x.fieldName==prop);
        let label; let value;
        label=column[0].label;
        if(column[0].type=='date'){
          label= prop.substring(0, 2) === "to"? "To "+ column[0].label : "From " +column[0].label;
          value=dayjs(appliedFilterValues[prop]).format('MM/DD/YYYY') 
        }
        else if(column[0].type=='multiselectDropdown' || column[0].type === 'contains') {
          value=appliedFilterValues[prop].join(", ");
        }
        else{ 
          value=appliedFilterValues[prop];
        }
         
        if(column!==undefined && column.length>0){  
          commandBarItems.push( 
            {
            key: 'filteredBy '+label,
            name: (
              <span>
               <span style={{ fontWeight: '500' }}>{label+' '} </span>
                {value}  
                <IconButton title='Remove Filter'
                onClick={() => removeFilterFromUI(prop,column[0].fieldName)} iconProps={{ iconName: 'ChromeClose' , style: { fontSize: '13px' } }} 
              />
              </span>
            ), 
            ariaLabel: 'Filtered By',  
            iconProps: { iconName: 'ClearFilter' } 
          }
        );         
        }
      }
    } 
    return commandBarItems;
    }

  
  const createbuttonsfromInputProps = (): ICommandBarItemProps[] => {
    let commandBarItems: ICommandBarItemProps[] = [];
    if(props.additionalControls!=undefined){
      props.additionalControls.forEach((element:any) => {
        var item = {
          key: element.key,
          text: element.text,
          ariaLabel: element.ariaLabel,
          iconProps: element.iconProps,
          onClick: () => {
            props.onClickAdditionalControls(element.text);
        },
           disabled:  element.disabled,
           subMenuProps: element.subMenuProps             
        }
        if(item.subMenuProps !== undefined) {
          item.subMenuProps.items.forEach((subElement:any) => {
            subElement.onClick = () => {props.onClickAdditionalControls(subElement.text);}
          });
        }
        commandBarItems.push(item); 
      });
    } 
    return commandBarItems;
    }

  const createCommandBarItemProps = (): ICommandBarItemProps[] => {
    let commandBarItems: ICommandBarItemProps[] = [];
    commandBarItems=createbuttonsfromInputProps();
    commandBarItems.push(
        {  
          key: 'columnSortBy', 
          text: 'Sort by', 
          ariaLabel: 'Sort by',
          disabled: false,
          cacheKey: 'myColumnSortByCacheKey', 
          iconProps: { iconName: 'Sort'}, 
          subMenuProps: {
            items: props.sortColumns.map((item:any) => {
              return {
                key: item.fieldName,
                text: item.label, 
                subMenuProps: {
                  items: [
                    {
                      key: 'ascending',
                      text: 'Ascending', 
                      onClick: () => onSorted(item.fieldName,"asc"),
                      iconProps: { iconName: 'Ascending'}
                    },
                    {
                      key: 'descending',
                      text: 'Descending', 
                      onClick: () => onSorted(item.fieldName,"desc"),
                      iconProps: { iconName: 'Descending'}
                    }  
                  ]      
                }
              };
            }), 
          } 
      }, 
      { 
        key: 'columnFilters',
        text: 'Filter',
        ariaLabel: 'Filter',
        disabled: false,
        cacheKey: 'myColumnFilterCacheKey',
        iconProps: { iconName: isFilterApplied?"FilterSolid":'Filter' },
        subMenuProps: {
          items: [
            {
              key: 'Filter',
              text: 'Filter',
              disabled: props.data === undefined || props.data.length === 0,
              iconProps: { iconName: 'Filter' },
              onClick: () => onFilterPanelOpen()
            },
            {
              key: 'clearFilters',
              text: 'Clear Filters',
              disabled: isFilterApplied ? false : true,
              iconProps: { iconName: 'ClearFilter' },
              onClick: () => onClickClearFilter()
            }
          ],
        }
      } 
    );
    createCommandBarActiveFilter(commandBarItems); 
    return commandBarItems;
  }
  // Mutating styles definition
  const stackStyles: IStackStyles = {
    root: {
      width: `20%`
    },
  };
  return (
    <div>  
      <div style={{borderBottom: "1px solid rgb(233 222 222)",width:"100%"}}>  
      <CommandBar styles={{ root: { padding: 0} }} items={createCommandBarItemProps()} farItems={[]} ariaLabel="Command Bar" /> 
      </div>
      <CoherencePanel
        title="Filter Data By Columns"
        titleText="Filter Data by Columns"
        isOpen={isPanelOpen}
        onDismiss={handleOnPanelClose}
        panelSize={CoherencePanelSize.medium}
        closeButtonAriaLabel="Close"
        onRenderFooter={() => (
          <Stack horizontal >
            <PrimaryButton text="Apply Filter"  onClick={() => onClickApplyFilter(null)}/>
            <PrimaryButton text="Save as Default Filter"  onClick={() => onClickSaveUserPref()}/>
            <DefaultButton text="Clear Filter" onClick={onClickClearFilter} />
          </Stack>
        )} 
      >
        {isPanelOpen ? generateControls() : ""}
      </CoherencePanel>
    </div>
  );
};

export default CustomGridControls;  
