import { CoherencePagination, ICoherencePaginationProps } from '@coherence-design-system/controls';
import React from 'react';
import { useEffect, useRef, useState } from 'react';  
interface prop{    
    data:any;
    updatePaginationData:any;   
    refreshPagination:any;
    pageSize:number;
    }    
const CustomPagination = (props:prop) => { 
       useEffect(() => {  
        //if data count is more then default page count
        if(props.data.length && (props.data.length>defaultPageSize.current)){
            totalRecords.current=props.data.length;
            onPageChange(1, props.pageSize, 1); 
        }
        //else send the data to update the grid 
        else{
            props.updatePaginationData(props.data); 
        }
       },  [props.refreshPagination]);
   
  const [canJumpToPage, setCanJumpToPage] = useState<boolean>(true);
  const selectedPage = useRef<number>(1);
  const pageCount = useRef<number>(props.pageSize);
  const defaultPageSize = useRef<number>(props.pageSize); 
  const totalRecords = useRef<number>(props.data.length);
  const onPageChange = (startItemIndex: number, endItemIndex: number, newSelectedPage: number): void => {
    selectedPage.current = newSelectedPage;
    fetchData(startItemIndex, endItemIndex, newSelectedPage,props.data);  
  };

  
  const fetchData = (startItemIndex: number, endItemIndex: number, newSelectedPage: number,data:any): void => {  
    selectedPage.current = newSelectedPage;  
    pageCount.current = Math.ceil(totalRecords.current / defaultPageSize.current);   

      // Logic for displaying current records
      const indexOfLastRecord = selectedPage.current * defaultPageSize.current;
      const indexOfFirstRecord = indexOfLastRecord - defaultPageSize.current;
      let fetchCurrentPageRecords=data.slice(indexOfFirstRecord, indexOfLastRecord);  
 // update the parent component's state with the paginated data
     props.updatePaginationData(fetchCurrentPageRecords); 
  }; 

  const paginationProps: ICoherencePaginationProps = {
    pageCount: pageCount.current,
    pageSize: defaultPageSize.current,
    selectedPage: selectedPage.current,
    previousPageAriaLabel: 'previous',
    nextPageAriaLabel: 'next',
    inputFieldAriaLabel: 'page number',
    onPageChange: onPageChange,
    canJumpToPage: canJumpToPage    
  }; 
  return (
    <> 
       {(props.data!==undefined && props.data.length>defaultPageSize.current) && <CoherencePagination {...paginationProps} />}
    </>
  );
};
export default CustomPagination;


