import { msalConfig, IdTokenClaims, appRoles } from "../authConfig";
import { AccountInfo } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";

export function GetUserRole() {
    const { instance } = useMsal();

    const currentAccount: AccountInfo = instance.getActiveAccount() as any;
    if (currentAccount) {
        if (currentAccount.tenantId === msalConfig.auth.tenantId) {
            const idTokenClaims = currentAccount.idTokenClaims as IdTokenClaims;
            if (idTokenClaims && idTokenClaims.aud === msalConfig.auth.clientId
                     && idTokenClaims["roles"] && idTokenClaims["roles"].length > 0) {                       
                return  idTokenClaims["roles"]; 
            }
        }
    }
    return "";
}