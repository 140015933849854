import { CoherenceLoading } from "@coherence-design-system/controls";
import { Card } from "@coherence-design-system/card";


import React from "react";

export const CoherenceLoadingHelper: React.FunctionComponent = () => { 
  return (
    <div>    
   <Card>
        <CoherenceLoading primaryText="" />
      </Card>
   </div>
  );
};
export default CoherenceLoadingHelper;