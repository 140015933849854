import { IStyle, mergeStyleSets } from "@fluentui/react"; 
export const PORDetailsStyles = mergeStyleSets({
    breadCrumbPosition:{ 
        margin: '6px 0px 6px 0px'
      }
});
export const UnAuthorizedStyles = mergeStyleSets({
  unauthorizedHeader: {  
    color: 'red'   
  }
  
});
