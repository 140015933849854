import React, { useState } from "react";  
import DataMonitoringIssues from "./DataMonitoringIssues/DataMonitoringIssues"; 
import CatalogAlignment from "./CatalogAlignment/CatalogAlignment";
import RequirementDefinitions from "./RequirementDefinitions/RequirementDefinitions"; 
import Accordion from "../../shared/Accordion/Accordion"; 
import { GetUserRole } from "../../utils/GetUserRole";

export const NonManaged: React.FunctionComponent = () => { 
  const [userRole, setUserRole] = useState(GetUserRole());
return <div> 
  <h2>Non Managed Data</h2>
<div>
<Accordion title="Data Monitoring Issues" isExpandDefault={true}>
    <DataMonitoringIssues></DataMonitoringIssues>
  </Accordion>

 
<Accordion title="Catalog Alignment" isExpandDefault={true}>
<CatalogAlignment></CatalogAlignment>  
</Accordion> 
 
<Accordion title="Requirement Definitions" isExpandDefault={true}>
<RequirementDefinitions></RequirementDefinitions>
  </Accordion>
</div> 
</div>; 
};
export default NonManaged;