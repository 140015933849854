import React, { useEffect, useState } from "react";
import { CoherenceDataGrid, CoherenceDataGridColumn, CoherenceLoading, CoherenceNotificationType, CoherencePanel, CoherencePanelSize, itemSpacing } from "@coherence-design-system/controls";
import { DOMEApiHelper,calculateTimeAgo, isEmpty, onFormatDate } from "../../utils/DOMEApiHelper";
import {  DatePicker, DefaultButton, Dialog, DialogType, DialogFooter, Dropdown, FontIcon, IDropdownOption, Label, Pivot, PivotItem, PrimaryButton, Stack, TextField, TooltipHost, Checkbox, ChoiceGroup,    DetailsListLayoutMode, ICommandBarItemProps, CommandBar, Link, ActivityItem, IActivityItemProps, mergeStyleSets, IconButton, MessageBar, MessageBarType, IChoiceGroupOption, SearchBox, TagPicker, ITag, IBasePickerSuggestionsProps} from "@fluentui/react";
import { useNavigate } from "react-router-dom";
import Accordion from "../../shared/Accordion/Accordion";
import CustomGridControls from "../../shared/CustomGridControls/CustomGridControls";
import ToastMessage from "../../shared/ToastMessage/ToastMessage";
import CustomPagination from "../../shared/CutomPagination/CustomPagination";
import { NotificationPivotOptions } from "../../shared/AppHeader/AppHeader.utils";
import dayjs from "dayjs";
import type { EditorPlugin } from 'roosterjs';
import { CustomRTEPlugin } from "../../shared/CustomRTEPlugin/CustomRTEPlugin";
import { PrimaryContactPicker } from "../../shared/PrimaryContactPicker/PrimaryContactPicker";
import { IntakeManagementStyles } from "./IntakeManagement.styles";
import IntakeDiscussions from "../NonManaged/NonManagedDiscussions/NonManagedDiscussions"; 
import { RichTextEditor } from "@coherence-design-system/rich-text-editor";
import { AnyMxRecord } from "dns";

const IntakeManagement: React.FunctionComponent = () => {
    const [toastType, setToastType] = useState<CoherenceNotificationType>(CoherenceNotificationType.SUCCESS);
    const [toastMessage, setToastMessage] = useState<string>("");
    const [toastTitle, setToastTitle] = useState<string>("");
    const [showToast, setShowToast] = React.useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [LoadText, setLoadText] = useState<string>("");
    const [IntakeManagementData, setIntakeManagementData] = useState<any>([]);
    const [isSelectedAll, setIsSelectedAll] = React.useState<boolean>(false);
    const [refreshPagination, setRefreshPagination] = useState<boolean>(false);
    const [intakePaginatedData, setIntakePaginatedData] = useState<any>([]);
    const [showEditGridPanel, setshowEditGridPanel] = useState<boolean>(false);
    const [DataMismatchAttributesTitle, setDataMismatchAttributesTitle] = useState<any>(); 
    const [referenceDetailsAttributes, setReferenceDetailsAttributes] = useState<any>();
    const [editColumnsDefaultValues, seteditColumnsDefaultValues] = useState<any>();
    const [originalEditData, setOriginalEditData] = useState<any>();
    const [modalOpen, setmodalOpen] = useState<boolean>(false);
    const [discussionData, setDiscussionData] = useState<any>(); 
    const [isDisabledSaveButton, setIsDisabledSaveButton] = useState(true);
    const [isEditMode, setIsEditMode] = useState(false);
    const [showCategoryErrorMessage, setCategoryShowErrorMessage] = useState(false);
    const [showSubCategoryErrorMessage, setSubcategoryShowErrorMessage] = useState(false);
    const [showPatternErrorMessage, setPatternShowErrorMessage] = useState(false);
    const [showCapabilityErrorMessage, setCapabilityShowErrorMessage] = useState(false);
    const [onSaveDataValid, setOnSaveDataValid] = React.useState<boolean>(false);
    const [primaryContactRequired, setAssignedToRequired] = React.useState<boolean>(true);
    const [selectedDescription, setSelectedDescription] = useState<any>();
    const [refIDsColumns, setRefIDsColumns] = useState<any>([]);
    const [refIDsData, setRefIDsData] = useState<any>([]);
    const [rulesRecommendation, setRulesRecommendation] = useState<any>();
    const [linkedPorIds, setLinkedPorIds] = useState<any>([]);
    const [disableCreatePORButon, setDisableCreatePORButon] = useState<boolean>(true);
    const [PorIdList, setPorIdList] = useState<any>([{key:'', text:''}]);
    const [description, setDescription] = useState<any>();
    let navigate = useNavigate();
  

    const LoadPorlist = () => {
    
      setActiveTab(NotificationPivotOptions.REVIEWPENDING);
      DOMEApiHelper('PORItems/GetAllPORItemsDataAsync', requestOptionsGETALL)
        .then(res => {       
          if (res !== undefined && res.length > 0 && res[0]["PORItemsList"].length > 0) {
            res[0]["PORItemsList"].forEach((item: any, index: any) => {
              PorIdList.push({ key: item.PORID, text: item.PORID });
            });
          }
          setPorIdList(PorIdList);  
        }).catch(error => {
          setLoadText("");
          setIsLoaded(false);
          setToastTitle("Error while fetching POR Items data");
          setToastMessage(error.toString());
          setToastType(CoherenceNotificationType.ERROR);
          setShowToast(true);
          setTimeout(() => {
            setShowToast(false);
          }, 5000);
        });
    }
  

    //Pivot related code start
  const [activeTab, setActiveTab] = useState<NotificationPivotOptions>(NotificationPivotOptions.ALLITEMS);
  const handlePivotChange = (item: any) => {
    resetSelection();
    if (item.props.itemKey === NotificationPivotOptions.ALLITEMS) {
      setIntakeManagementData(isFilterApplied ? intakeFilteredData : intakeItemsBeforeFilterData);
        if (sortColumnDetails !== undefined && sortColumnDetails["columnName"] !== undefined)
            sortDefault(isFilterApplied ? intakeFilteredData : intakeItemsBeforeFilterData)  //sort bydefault     
        else resetPagination();
    }
    else if (item.props.itemKey === NotificationPivotOptions.REVIEWPENDING) {
      setIntakeManagementData((isFilterApplied ? intakeFilteredData : intakeItemsBeforeFilterData).filter((data: any) => isEmpty(data.Status) || data.Status=='To be Reviewed' || data.Status=='Review In Progress'));
        if (sortColumnDetails !== undefined && sortColumnDetails["columnName"] !== undefined)
            sortDefault((isFilterApplied ? intakeFilteredData : intakeItemsBeforeFilterData).filter((data: any) =>  isEmpty(data.Status) || data.Status=='To be Reviewed' || data.Status=='Review In Progress'));
        else resetPagination();
    }
    else if (item.props.itemKey === NotificationPivotOptions.REVIEWCOMPLETED) {
        setIntakeManagementData((isFilterApplied ? intakeFilteredData : intakeItemsBeforeFilterData).filter((data: any) => data.Status=='Review Completed' || data.Status =='No DOME WorkFlow'));
        if (sortColumnDetails !== undefined && sortColumnDetails["columnName"] !== undefined)
            sortDefault((isFilterApplied ? intakeFilteredData : intakeItemsBeforeFilterData).filter((data: any) => data.Status=='Review Completed' || data.Status =='No DOME WorkFlow'));
        else resetPagination();
    }
    setActiveTab(item.props.itemKey);
};

  

  const getDataCountByActionType = (inputType: any) => {
    if (inputType === NotificationPivotOptions.ALLITEMS) {
        return isFilterApplied ? intakeFilteredData.length : intakeItemsBeforeFilterData.length;
    }
    else if (inputType === NotificationPivotOptions.REVIEWPENDING) {
        return (isFilterApplied ? intakeFilteredData : intakeItemsBeforeFilterData).filter((data: any) => isEmpty(data.Status) || data.Status=='To be Reviewed' || data.Status=='Review In Progress').length
    }
    else if (inputType === NotificationPivotOptions.REVIEWCOMPLETED) {
        return (isFilterApplied ? intakeFilteredData : intakeItemsBeforeFilterData).filter((data: any) => data.Status=='Review Completed' || data.Status =='No DOME WorkFlow').length
    }
}

  const openModal = (item: any) => { 
    getPopUpdata(item);
  }; 

  const getPopUpdata = (item: any) => {       
    
    // Parse the corrected JSON string into an object 
    setReferenceDetailsAttributes(item.ReferenceDetails); 
    setDataMismatchAttributesTitle("Reference Details");      
    setmodalOpen(true);           
  }

  const getReferenceDetailsAttributes= (): any => {
    let tmpRenderObj: any[] = [];   
    for (let data in referenceDetailsAttributes) {
      if(data!='Comments' && data!='Target Launch Date'){
        tmpRenderObj.push(
          <><div style={{ display: "flex" }} >
            <div style={{ width: "40%", textAlign: "left" }}>
              <Label>{data}</Label>
            </div>
            <div style={{width:"70%",padding: "5px"}}>: {String(referenceDetailsAttributes[data])}</div>
          </div>
          </>
        );   
      } 
    }  
      return tmpRenderObj;
  }

  const handleOnPanelJSONXClose = () => {
    setmodalOpen(false);
  }  

  const handleOnPanelXClose = () => {  
    setshowEditGridPanel(false); 
    setDiscussionData([]);
  }

  const handleOnPanelSave = () => {  
    let IntakediscussionData = {  
      Id: editColumnsDefaultValues.Id,
      DiscussionId: discussionData.DiscussionId,
      DiscussionType: discussionData.DiscussionType,
      Discussion: discussionData.Discussion,
      CreatedBy: discussionData.CreatedBy,
      Status: editColumnsDefaultValues.Status
    }
   
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(IntakediscussionData),
        mode: "cors",
      };
      setToastTitle("Edit Intake Data"); 
      setToastMessage("Updating Intake Data is in progress");
      setToastType(CoherenceNotificationType.PENDING);
      setShowToast(true);
      DOMEApiHelper('IntakeManagement/SaveIntakeDetails', requestOptions)
        .then(res => {
          setToastType(CoherenceNotificationType.SUCCESS);
          setToastMessage("Intake Data has been updated successfully.");
          setTimeout(() => {
            setShowToast(false);
          }, 3000);
          
          if (res.Message === "Success") {
            setIsLoaded(false);
            setIsFilterApplied(false);
            setshowEditGridPanel(false);
            LoadIntakeManagementData("Loading data")
          }
        }).catch(res => {
          setToastMessage(res.toString());
          setToastType(CoherenceNotificationType.ERROR);
          setTimeout(() => {
            setShowToast(false);
          }, 3000);
        });
  }

  const handleOnPanelCreatePOR = () => {   
  
    if (!isEditMode){
      if (!isDataValid(editColumnsDefaultValues)) {
        return false;
      }
      if(!isEmpty(discussionData.Discussion)){
        handleOnPanelSave();
      }
      var createdByID = sessionStorage.getItem("localAccountId");
      let vsDate = dayjs(editColumnsDefaultValues.TargetCompletionDate).startOf("day").toDate();
      const formattedDate = dayjs(vsDate).format("YYYY-MM-DDTHH:mm:ss.SSS");    
      //add references contained in refIDsData to the references array
      const referenceDetailsListKeys = referenceDetailList.map((item: any) => item.text);
      const refIDsDataKeys = Object.keys(refIDsData);

      let referenceTypeID=referenceDetailList.filter((item: any) =>  (item.text == (editColumnsDefaultValues.Source=='CT'?'Control Tower ID':'OneMap ID')) && item.ServiceLine === editColumnsDefaultValues.ServiceLineName );
      let references: { ReferenceType: string, ReferenceValue: string }[] = [];
      if(referenceTypeID != 0 ) {  
        references = [{"ReferenceType": referenceTypeID[0].key,"ReferenceValue": editColumnsDefaultValues.SourceLink}]; 
      }
      
      refIDsDataKeys.forEach((key) => {
        let value = key === "OneMapID" ? "OneMap ID" : key
        if (referenceDetailsListKeys.includes(value) && refIDsData[key] !== "null" && refIDsData[key] !== "") {        
          // Add the value from refIDsData to the variable here
          //added default service line check
          let referenceType = referenceDetailList.filter((item: any) => (item.text === value && item.ServiceLine === editColumnsDefaultValues.ServiceLineName ))
          if(referenceType.length !== 0) {references.push({ "ReferenceType": referenceType[0].key, "ReferenceValue": refIDsData[key] }); }
          }
        if(key === "Parent"){
          let referenceType = referenceDetailList.filter((item: any) => ( editColumnsDefaultValues.Source=='CT'? item.text === "Parent Control Tower ID" && item.ServiceLine === editColumnsDefaultValues.ServiceLineName : item.text === "Parent OneMap ID" && item.ServiceLine === editColumnsDefaultValues.ServiceLineName) )
          if(referenceType !== 0) {references.push({ "ReferenceType": referenceType[0].key, "ReferenceValue": refIDsData[key] }); }
        }
      });
      //fetching requested by contact id for the selected serviceline
      let requestedByContactTypes = contactTypeList.filter((item: any) =>  item.text === "Requested By" && item.ServiceLine === editColumnsDefaultValues.ServiceLineName );
      
      let data = {
        Id: editColumnsDefaultValues.Id,
        Title: editColumnsDefaultValues.Title,
        TargetCompletionDate: formattedDate,
        PrimaryContact: (editColumnsDefaultValues.PrimaryContact !== undefined && editColumnsDefaultValues.PrimaryContact.length > 0) ? editColumnsDefaultValues.PrimaryContact[0].objectId : "",
        Description: editColumnsDefaultValues.Description,
        serviceLineData: editColumnsDefaultValues.ServiceLineName,
        serviceLineId: editColumnsDefaultValues.ServiceLineId,
        Category: editColumnsDefaultValues.Category,
        SubCategory: editColumnsDefaultValues.SubCategory,
        Pattern: editColumnsDefaultValues.Pattern,
        Capability: editColumnsDefaultValues.Capability,
        CreatedBy: createdByID,
        Status: "Review Completed",
        References: references,
        Contacts: !isEmpty(editColumnsDefaultValues.RequestedBy) && requestedByContactTypes.length!==0 ? 
         [{"ContactType": requestedByContactTypes[0].key ,
          "ContactGUID": editColumnsDefaultValues.RequestedBy}] : []
      
      };

      const formData = new FormData();
        formData.append('createPORJSON', JSON.stringify(data));
        const requestOptions = {
          method: 'PUT',
          headers: { 'Content-Type': 'multipart/form-data' },
          body: formData,
          mode: 'cors'
        };
        setToastTitle("Create POR  ");
        setToastMessage("Creating POR");
        setToastType(CoherenceNotificationType.PENDING);
        setShowToast(true);
        DOMEApiHelper('IntakeManagement/CreateIntakePORItem', requestOptions)
          .then(res => {
            setToastType(CoherenceNotificationType.SUCCESS);
            setToastMessage("POR has been created successfully.");
            setTimeout(() => {
              setShowToast(false);
            }, 3000);
            setIsDisabledSaveButton(true); 
            if (res > 0) {
           
              setshowEditGridPanel(false);
              LoadIntakeManagementData("Loading data")
            }
          }).catch(res => {
            setToastMessage(res.toString());
            setToastType(CoherenceNotificationType.ERROR);
            setTimeout(() => {
              setShowToast(false);
            }, 3000);
          });

     }
  
}; 


const LoadPatterns = (id: any, dataobj? :any) => {
  const requestOptions = {
    method: 'Get',
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors'
  };
  
  DOMEApiHelper('PORDetails/GetPatternDetail?Id=' + id, requestOptions)
    .then(res => {   
      if(res.length===0){
        return false;
      }
      setPatternsCombinationList(res);
      let catalogCategoryList = (res).map((item: any) => {
        return {
          key: item.CatalogCategoryId,
          text: item.CatelogCategoryTitle,
          IsPatternRequired: item.IsPatternRequired
        }
      })
      let uniqueCatalogCategoryList = uniquearray(catalogCategoryList);
      setCatalogCategoryList([...uniqueCatalogCategoryList]);
      //if category has only one option 
      if (uniqueCatalogCategoryList.length === 1) {  
        if(!isEmpty(dataobj)){
          dataobj.Category = uniqueCatalogCategoryList[0].key;
        }else{
          editColumnsDefaultValues.Category = uniqueCatalogCategoryList[0].key 
        }     
        seteditColumnsDefaultValues(isEmpty(dataobj) ? editColumnsDefaultValues: dataobj);
        let getSubCategoryByCategory = res.filter((x: any) => x.CatalogCategoryId === uniqueCatalogCategoryList[0].key);
        let subCategoryListFiltered = (getSubCategoryByCategory).map((item: any) => {
          return {
            key: item.SubCategoryId,
            text: item.SubCategoryTitle
          }
        })
        let uniqueSubCategoryList= uniquearray(subCategoryListFiltered);
      setSubCategoryList([...uniqueSubCategoryList]);
      setIsDisabledSaveButton(false); 
      //if subcategory has only one option as n/a then select bydefault and populate paterns dropdown
      if (uniqueSubCategoryList.length === 1 && uniqueSubCategoryList.filter((x: any) => x.text === "n/a").length > 0) {
        if(!isEmpty(dataobj)){
          dataobj.SubCategory = uniqueSubCategoryList[0].key;
        }else{
          editColumnsDefaultValues.SubCategory = uniqueSubCategoryList[0].key 
        } 
        seteditColumnsDefaultValues(isEmpty(dataobj) ? editColumnsDefaultValues: dataobj);
        let getPatternsBySubCategory = res.filter((x: any) => (x.CatalogCategoryId=== editColumnsDefaultValues.Category && x.SubCategoryId === uniqueSubCategoryList[0].key));
        let patternsListFiltered = (getPatternsBySubCategory).map((item: any) => {
          return {
            key: item.PatternId,
            text: item.PatternTitle
          }
        })
        let uniquePatternList= uniquearray(patternsListFiltered);
        setPatternList(uniquePatternList);
        
        if(!isEmpty(dataobj) && !isEmpty(dataobj.MLRecommendation["Pattern"])){ 
          dataobj.Capability = 0; 
          dataobj.Pattern=searchPattern(uniquePatternList,dataobj);
          if(!isEmpty(dataobj.Pattern)){
            //bydefault select  capability logic start
            let getCapabilitysByPattern = res.filter((x: any) => (x.CatalogCategoryId=== editColumnsDefaultValues.Category && x.SubCategoryId === editColumnsDefaultValues.SubCategory && x.PatternId === dataobj.Pattern));
            let capabilityListFiltered = (getCapabilitysByPattern).map((item: any) => {
              return {
                key: item.CapabilityId,
                text: item.CapabilityTitle
              }
            })
            let uniqueCapabilityList = uniquearray(capabilityListFiltered);
            setCapabilityList([...uniqueCapabilityList]);
            dataobj.Capability =searchCapability(res,dataobj);
            //bydefault select  capability logic start
          }
        }
        else{
          editColumnsDefaultValues.Pattern = 0;
          editColumnsDefaultValues.Capability = 0;
        }
      
        seteditColumnsDefaultValues(isEmpty(dataobj) ? editColumnsDefaultValues: dataobj);
      }
      else if(uniqueSubCategoryList.length === 1){
        isEmpty(dataobj) ? editColumnsDefaultValues.SubCategory = uniqueSubCategoryList[0].key : dataobj.SubCategory = uniqueSubCategoryList[0].key;
        seteditColumnsDefaultValues(isEmpty(dataobj) ? editColumnsDefaultValues: dataobj);
      }
      else {
        if(!isEmpty(dataobj)){
          let subCategoryToSelectDefault = uniqueSubCategoryList.filter((data: any) => data.text ===dataobj.MLRecommendation["Sub Category"]); 
          if(!isEmpty(subCategoryToSelectDefault)){
           dataobj.SubCategory = subCategoryToSelectDefault[0].key;

           let getPatternsBySubCategory = res.filter((x: any) => (x.CatalogCategoryId=== editColumnsDefaultValues.Category && x.SubCategoryId === subCategoryToSelectDefault[0].key));
           let patternsListFiltered = (getPatternsBySubCategory).map((item: any) => {
             return {
               key: item.PatternId,
               text: item.PatternTitle
             }
           })
           let uniquePatternList= uniquearray(patternsListFiltered);
           setPatternList(uniquePatternList);
           if(!isEmpty(dataobj.MLRecommendation["Pattern"])){ 
            dataobj.Capability = 0;
            dataobj.Pattern=searchPattern(uniquePatternList,dataobj);
            if(!isEmpty(dataobj.Pattern)){
               //bydefault select  capability logic start
               let getCapabilitysByPattern = res.filter((x: any) =>(x.CatalogCategoryId=== editColumnsDefaultValues.Category && x.SubCategoryId === editColumnsDefaultValues.SubCategory &&  x.PatternId === dataobj.Pattern));
               let capabilityListFiltered = (getCapabilitysByPattern).map((item: any) => {
                 return {
                   key: item.CapabilityId,
                   text: item.CapabilityTitle
                 }
               })
               let uniqueCapabilityList = uniquearray(capabilityListFiltered);
               setCapabilityList([...uniqueCapabilityList]);
               dataobj.Capability =searchCapability(res,dataobj);            
             //bydefault select  capability logic start  
            }
          }
          else{
            editColumnsDefaultValues.Pattern = 0;
            editColumnsDefaultValues.Capability = 0;
          }

          }
          else{
           dataobj.SubCategory = 0;
          }

        } 
        else{
          editColumnsDefaultValues.SubCategory = uniqueSubCategoryList[0].key 

        } 
        seteditColumnsDefaultValues(isEmpty(dataobj) ? editColumnsDefaultValues: dataobj);        
      }
    }
    else {

    }


      setshowEditGridPanel(true);
    }).catch(error => {
      // setIsLoaded(false); 
    });
}

const searchPattern=(data:any,dataobj:any)=>{ 
  let patternId=0
  let patternToSelectDefault = data.filter((data: any) => data.text ===dataobj.MLRecommendation["Pattern"]); 
   if(!isEmpty(patternToSelectDefault)){
    patternId = patternToSelectDefault[0].key;
   } 
   return patternId;
}
const searchCapability=(data:any,dataobj:any)=>{ 
  let capabilityId=0
  let capabilityToSelectDefault = data.filter((data: any) => data.CapabilityTitle ===dataobj.MLRecommendation["Capability"]); 
   if(!isEmpty(capabilityToSelectDefault)){
    capabilityId = capabilityToSelectDefault[0].CapabilityId;
   } 
   return capabilityId; 
}

// Pattern
const onChangePattern = (event: React.FormEvent<HTMLDivElement>, item?: any, index?: number): void => {
  setIsDisabledSaveButton(false); 
  let getCapabilitysByPattern = patternsCombinationList.filter((x: any) => ( x.CatalogCategoryId=== editColumnsDefaultValues.Category && x.SubCategoryId === editColumnsDefaultValues.SubCategory && x.PatternId === item?.key));
  let capabilityListFiltered = (getCapabilitysByPattern).map((item: any) => {
    return {
      key: item.CapabilityId,
      text: item.CapabilityTitle
    }
  })
  let uniqueCapabilityList = uniquearray(capabilityListFiltered);
  setCapabilityList([...uniqueCapabilityList]);
    
  if(uniqueCapabilityList.length ===1 ?  editColumnsDefaultValues.Capability=uniqueCapabilityList[0].key :  editColumnsDefaultValues.Capability = 0 )
  if(uniqueCapabilityList.length===1){
    editColumnsDefaultValues.Capability =uniqueCapabilityList[0].key;
    seteditColumnsDefaultValues(editColumnsDefaultValues);
    onChangeCapability(event,uniqueCapabilityList[0]);
  }  
  editColumnsDefaultValues.Pattern = item.key;
  seteditColumnsDefaultValues(editColumnsDefaultValues);
  setPatternShowErrorMessage(false);
  disablePORSaveButton();
};





//RichTextField
const handleRichTextChange = (value: string | undefined): void => {
   seteditColumnsDefaultValues({ ...editColumnsDefaultValues, Description: value });   
} 

//plugin code start 
const getPlugins = () => {
  let plugins: EditorPlugin[] = [];
  plugins.push(
    new CustomRTEPlugin((content) => {
    })
  );
  return plugins;
};
//plugin code end 

const isDataValid = (data: any) => {

  // Create POR validation
  if ( (!isEditMode) && (isEmpty(data.Title) || isEmpty(data.PrimaryContact) || isEmpty(data.Description) || isEmpty(data.TargetCompletionDate) || isEmpty(data.Category) || isEmpty(data.SubCategory)|| isEmpty(data.Pattern)|| isEmpty(data.Capability))) {
    return false;
  } 
   return true;
};

const onChangeCategory = (event: React.FormEvent<HTMLDivElement>, item?: any, index?: number): void => {
  editColumnsDefaultValues.Category = item.key;
  editColumnsDefaultValues.Pattern = 0;
  editColumnsDefaultValues.Capability = 0;
  seteditColumnsDefaultValues(editColumnsDefaultValues);
  setSubCategoryList([]);
  setPatternList([]);
  setCapabilityList([]);
  setIsDisabledSaveButton(true);
  setCategoryShowErrorMessage(false);
  disablePORSaveButton();

  let getSubCategoryByCategory = patternsCombinationList.filter((x: any) => x.CatalogCategoryId === item.key);
    let SubCategoryListFiltered = (getSubCategoryByCategory).map((item: any) => {
      return {
        key: item.SubCategoryId,
        text: item.SubCategoryTitle
      }
    })
    let uniqueSubCategoryList = uniquearray(SubCategoryListFiltered)
    setSubCategoryList([...uniqueSubCategoryList]);

    //if subcategory has only one option as n/a then select bydefault and populate paterns dropdown 
    if ((uniqueSubCategoryList.length === 1 && uniqueSubCategoryList.filter((x: any) => x.text === "n/a").length > 0) || uniqueSubCategoryList.length === 1) {          
      editColumnsDefaultValues.SubCategory = uniqueSubCategoryList[0].key;
      seteditColumnsDefaultValues(editColumnsDefaultValues);
      onChangeSubCategory(event,uniqueSubCategoryList[0]);
    }
    else {
      editColumnsDefaultValues.SubCategory = 0
      seteditColumnsDefaultValues(editColumnsDefaultValues);
      
    }

};

// Subcategory 
const onChangeSubCategory = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {  
  setIsDisabledSaveButton(false); 
  let getPatternsBySubCategory = patternsCombinationList.filter((x: any) => (x.CatalogCategoryId=== editColumnsDefaultValues.Category &&  x.SubCategoryId === item?.key));
  let patternsListFiltered = (getPatternsBySubCategory).map((item: any) => {
    return {
      key: item.PatternId,
      text: item.PatternTitle
    }
  })
  let uniquePatternList = uniquearray(patternsListFiltered)
  setPatternList([...uniquePatternList]);
    
  if(uniquePatternList.length===1 ){
    editColumnsDefaultValues.Pattern =uniquePatternList[0].key;
    editColumnsDefaultValues.SubCategory = item?.key
    seteditColumnsDefaultValues(editColumnsDefaultValues);
    onChangePattern(event,uniquePatternList[0]);
  }
  else{
  editColumnsDefaultValues.Capability = 0;  
  seteditColumnsDefaultValues(editColumnsDefaultValues);
  setCapabilityList([]);
  }
  
  editColumnsDefaultValues.SubCategory = item?.key;
  seteditColumnsDefaultValues(editColumnsDefaultValues);
  setSubcategoryShowErrorMessage(false);
  disablePORSaveButton();
};


// Capability
const onChangeCapability = (event: React.FormEvent<HTMLDivElement>, item?: any, index?: number): void => {
  setIsDisabledSaveButton(false); 
  editColumnsDefaultValues.Capability = item.key;
  seteditColumnsDefaultValues(editColumnsDefaultValues);
  setCapabilityShowErrorMessage(false);    
  disablePORSaveButton();
};

//Associate POR code start
const [selectedOption, setSelectedOption] = useState<string>('CreatePOR');
const [disableAssociate, setDisableAssociate] = React.useState<boolean>(true);
const [filteredDOMEIDValue, setFilteredDOMEIDValue] = useState<any>([]);
const getTextFromItem = (item: ITag) => item.name;

const associateoptions: IChoiceGroupOption[] = [
  { key: 'CreatePOR', text: 'Create POR' },
  { key: 'AssociatePOR', text: 'Associate POR' }
];
//search Textbox code start
const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
suggestionsHeaderText: 'Suggested Result(s)', 
noResultsFoundText:'No results found'
}; 

const filterSuggestedTags = async (filterText: string, tagList?: ITag[] | undefined, object?: any): Promise<ITag[]> => {
  const suggestionsHeaderText = 'Suggested Result(s)';
  let minChar=0;
  let noResultsFoundText='No results found'; 
  if(!isEmpty(filterText) && !isEmpty(object.additionalData)){
    minChar=object.additionalData.minChar;
    noResultsFoundText = filterText.length >= minChar ? 'No results found' : 'Enter a minimum of '+minChar+' characters for result(s)';
  } 
  // Update pickerSuggestionsProps dynamically
  pickerSuggestionsProps.suggestionsHeaderText = suggestionsHeaderText;
  pickerSuggestionsProps.noResultsFoundText = noResultsFoundText;
  // Access 'object' and use it as needed 
  let result=[]; 
  if(!isEmpty(filterText)&& filterText.length >= minChar){
  result=  object.filter(
      (tag:any) => tag.text.toString().indexOf(filterText.toString()) === 0 && !listContainsTagList(tag, tagList),
    ).map((item:any) => ({ name: item.text.toString() })) 
  }
  return result;
}

const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
  if (!tagList || !tagList.length || tagList.length === 0) {
    return false;
  }
  return tagList.some(compareTag => compareTag.key === tag.key);
};

const handleOnPanelAssociatePOR =() => {
let AssociatePOR = {  
  Id: editColumnsDefaultValues.Id,
  DOMEID: filteredDOMEIDValue.DOMEID,
  Status: 'Review Completed'
}
const requestOptions = {
  method: "PUT",
  headers: { "Content-Type": "application/json" },
  body: JSON.stringify(AssociatePOR),
  mode: "cors",
};
setToastTitle("Edit Intake Data"); 
setToastMessage("POR Association is in progres");
setToastType(CoherenceNotificationType.PENDING);
setShowToast(true);
DOMEApiHelper('IntakeManagement/SaveIntakeDetails', requestOptions)
  .then(res => {
    setToastType(CoherenceNotificationType.SUCCESS);
    setToastMessage("Intake Data has been updated successfully.");
    setTimeout(() => {
      setShowToast(false);
    }, 3000);
    
    if (res.Message === "Success") {
      setIsLoaded(false);
      setIsFilterApplied(false);
      setshowEditGridPanel(false);
      LoadIntakeManagementData("Loading data")
    }
  }).catch(res => {
    setToastMessage(res.toString());
    setToastType(CoherenceNotificationType.ERROR);
    setTimeout(() => {
      setShowToast(false);
    }, 3000);
  });
}


//Associate POR code end

//PrimaryContact
const onSelectPrimaryContact = (contact: any) => {
  setIsDisabledSaveButton(false);
 if (isEmpty(contact)) {
   editColumnsDefaultValues.PrimaryContact = [];
 }
 else {
   editColumnsDefaultValues.PrimaryContact = [contact];
 }
 seteditColumnsDefaultValues(editColumnsDefaultValues);
 disablePORSaveButton();
}

const onChangeStatus = (event: React.FormEvent<HTMLDivElement>, item?: any, index?: number): void => { 
  seteditColumnsDefaultValues({ ...editColumnsDefaultValues, Status: item.key });
};

const handleDateSelection = (date: any) => {
  seteditColumnsDefaultValues({ ...editColumnsDefaultValues, TargetCompletionDate: new Date(date) });
  disablePORSaveButton();
}

const handleIsPatternRequired = (data: any) => {
  const isCategoryValid = data.isPatternRequired && (data.Category === 0 || data.Category === undefined);
  setCategoryShowErrorMessage(isCategoryValid);
  const isSubcategoryValid = data.isPatternRequired && (data.SubCategory === 0 || data.SubCategory === undefined) && !isEmpty(subCategoryList);
  setSubcategoryShowErrorMessage(isSubcategoryValid);
  const isPatternValid = data.isPatternRequired && (data.Pattern === 0 || data.SubCategory === undefined) && !isEmpty(patternsList);
  setPatternShowErrorMessage(isPatternValid);
  const isCapabilityValid = data.isPatternRequired && (data.Capability === 0 || data.SubCategory === undefined) && !isEmpty(capabilityList);
  setCapabilityShowErrorMessage(isCapabilityValid);
}  
  const renderKeyValueHTML = (key: any,value:any) => {
    return  <div key={key} style={{ display: "flex" }}>
    <div style={{ flex: "0 0 auto", textAlign: "left", whiteSpace: "nowrap",fontWeight: 'bold' }}>
       {key} 
    </div>
    <div>
      : {value}
    </div>
  </div>; 
  };

  const renderKeyValuePairs = (data: any) => {
    const rows = [];  
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const value = data[key];
        if (Array.isArray(value)) {
          rows.push(
            <div key={key}>
              <strong>{key}:</strong>
              {value.map((item, index) => (
                <div key={index} style={{ marginLeft: 20 }}>
                  {typeof item === 'object' ? renderKeyValuePairs(item) : item}
                </div>
              ))}
            </div>
          );
        } else if (typeof value === 'object' && value !== null) {
          rows.push(
            <div key={key}>
              <strong>{key}:</strong>
              <div style={{ marginLeft: 20 }}>
                {renderKeyValuePairs(value)}
              </div>
            </div>
          );
        } else {
          rows.push(
            <div key={key}>
              {renderKeyValueHTML(key, value)}
            </div>
          );
        }
      }
    }
    return <React.Fragment>{rows}</React.Fragment>;
  };  

// eslint-disable-next-line
const [editRowColumns, seteditRowColumns] = useState<any>(
  [
    { "isEditable": true, "type": "ReadOnly", "label": "Source", "key": "Source" },
    { "isEditable": true, "type": "Link", "label": "Source ID", "key": "SourceID" },  
    { "isEditable": true, "type": "ReadOnly", "label": "Requested By", "key": "RequestedByUserName" }, 
    { "isEditable": true, "type": "Text", "label": "Dome Title", "key": "Title" },
    { "isEditable": true, "type": "TextArea", "label": "Dome Description", "key":"Description" },
    { "isEditable": true, "type": "Date", "label": "Target Completion Date", "key": "TargetCompletionDate"},
    { "isEditable": true, "type": "contactpicker", "label": "Dome Primary Contact", "key": "PrimaryContact" },
    { "isEditable": true, "type": "Dropdown", "label": "Dome State", "key": "DomeState" },
    { "isEditable": true, "type": "Dropdown", "label": "POR Classification", "key": "PORClassification" },
    { "isEditable": true, "type": "Dropdown", "label": "Category", "key": "Category" },    
    { "isEditable": true, "type": "Dropdown", "label": "Sub Category", "key": "Sub Category" },
    { "isEditable": true, "type": "Dropdown", "label": "Pattern", "key": "Pattern" },
    { "isEditable": true, "type": "Dropdown", "label": "Capability", "key": "Capability" },
    { "isEditable": true, "type": "Dropdown", "label": "Status", "key": "Status" }
  ]
);
 const onChangePORTitle = (e: any, text: any) => { 
    seteditColumnsDefaultValues({ ...editColumnsDefaultValues, Title: text.value })
  }
const generateControls = (): any => {
  let tmpRenderObj: any[] = [];
  let tmpTableObj: any[] = [];
 
  
//References Table
tmpTableObj.push(
  <div style={{paddingBottom:'20px'}}>
   {!isEmpty(refIDsData) &&   <table style={{borderCollapse: 'collapse',
  width: '100%'}}> 
          
          <tr className={IntakeManagementStyles.tableheader}>
              <th className={IntakeManagementStyles.tableheader} >Reference Type</th>
              <th className={IntakeManagementStyles.tableheader} >Reference Value</th>
          </tr>  

          {Object.entries(refIDsData).map(([key, val]) => (
            <>{ !isEmpty(refIDsData[key]) && <tr className={IntakeManagementStyles.tableheader}>
              <td className={IntakeManagementStyles.tableheader}>{key}</td>
              <td className={IntakeManagementStyles.tableheader}>{refIDsData[key]}</td>
            </tr>}</>
          ))}            

      </table>}       
    </div>
); 


if(!isEmpty(editColumnsDefaultValues.RuleRecommendationsList)){
  // Collect all unique column names
  const allColumnNames = editColumnsDefaultValues.RuleRecommendationsList.reduce((columns:any, item:any) => {
    Object.keys(item).forEach(key => {
      if (!columns.includes(key)) {
        columns.push(key);
      }
    });
    return columns;
  }, []);

  
  //Recommendation Table
  tmpTableObj.push(
   <div style={{ padding: 1,width:"99%" }}>
   <Accordion title="Rule Recommendation" isExpandDefault={false}>   
     <div>
      <table style={{ borderCollapse: 'collapse', width: '99%' }}>
  <tr className={IntakeManagementStyles.tableheader}>
    {/* Render table headers dynamically */}
    {allColumnNames.map((columnName:any, index:any) => (
       <th key={index} className={IntakeManagementStyles.tableheader}>
        {columnName === "Rules Recommendation" ? "Recommendation" : columnName}
        </th>
       ))}
  </tr>
  {!isEmpty(editColumnsDefaultValues.RuleRecommendationsList) &&
    editColumnsDefaultValues.RuleRecommendationsList.map((item:any, index:any) => (
      <tr key={index}>
        {/* Render table cells dynamically based on keys */}
        {allColumnNames.map((key:any, index:any) => (
          <td key={index} className={IntakeManagementStyles.tableheader}>
            {item[key]}
          </td>
        ))}
      </tr>
    ))}
</table>
<br/><br/>
    </div> 
 </Accordion> 
   </div>
  )
 }

 if(!isEmpty(editColumnsDefaultValues.SourceChangesList)){
  // Collect all unique column names
  const allColumnNames = editColumnsDefaultValues.SourceChangesList.reduce((columns:any, item:any) => {
    Object.keys(item).forEach(key => {
      if (!columns.includes(key)) {
        columns.push(key);
      }
    });
    return columns;
  }, []);

  
  //Recommendation Table
  tmpTableObj.push(
   <div style={{ padding: 1,width:"99%" }}>
   <Accordion title="Source Changes" isExpandDefault={false}>   
     <div>
      <table style={{ borderCollapse: 'collapse', width: '99%' }}>
  <tr className={IntakeManagementStyles.tableheader}>
    {/* Render table headers dynamically */}
    {allColumnNames.map((columnName:any, index:any) => (
       <th key={index} className={IntakeManagementStyles.tableheader}>
        {columnName}
        </th>
       ))}
  </tr>
  {!isEmpty(editColumnsDefaultValues.SourceChangesList) &&
    editColumnsDefaultValues.SourceChangesList.map((item:any, index:any) => (
      <tr key={index}>
        {/* Render table cells dynamically based on keys */}
        {allColumnNames.map((key:any, index:any) => (
          <td key={index} className={IntakeManagementStyles.tableheader}>
            {item[key]}
          </td>
        ))}
      </tr>
    ))}
</table>
<br/><br/>
    </div> 
 </Accordion> 
   </div>
  )
 }

 if(!isEmpty(editColumnsDefaultValues.MLRecommendation)){
  tmpTableObj.push(
    <div style={{ padding: 1,width:"99%" }}>
    <Accordion title="ML Recommendation" isExpandDefault={false}>  
      <Stack horizontal styles={{ root: {  justifyContent: 'flex-end'}}} tokens={{childrenGap: 10}}> 
      <Stack tokens={{ childrenGap: 20 }} styles={{ root: {  margin: 'auto', width:"100%" }}}>
          {renderKeyValuePairs(editColumnsDefaultValues.MLRecommendation)}
        </Stack>
      </Stack>
  </Accordion> 
    </div>
  )
}
 
  for (let editRowColumn of editRowColumns) {
      switch (editRowColumn.type) {  
      case 'ReadOnly': 
      tmpRenderObj.push(
            <div>
          <Label >{editRowColumn.label}
              <Label className="readonyLabel" style={{ height:'30px',margin:'5px 0px 0px 0px' }} disabled>{editColumnsDefaultValues[editRowColumn.key]}</Label>
          </Label>
        </div>
          ); 
          break;
       case 'Link':
        tmpRenderObj.push(
          <div>
          <Label >{editRowColumn.label}</Label>
          <Link href={editColumnsDefaultValues['SourceLink']} target="_blank">
             {editColumnsDefaultValues[editRowColumn.key]}
           </Link> 
        </div>
          ); 
          break;
          
      case 'Dropdown': 
          if (editRowColumn.label === 'Status') {
            if(editColumnsDefaultValues.Status==='Review Completed'){
            var options = [{ key: "To be Reviewed", text: "To be Reviewed" }, { key: "Review In Progress", text: "Review In Progress" }, { key: "No DOME WorkFlow", text: "No DOME WorkFlow" },  { key: "Review Completed", text: "Review Completed" }];
            }
            else {
              var options = [{ key: "To be Reviewed", text: "To be Reviewed" }, { key: "Review In Progress", text: "Review In Progress" }, { key: "No DOME WorkFlow", text: "No DOME WorkFlow" }];
            }
            tmpRenderObj.push(
              <> 
              <div>
                <Dropdown
                    label={editRowColumn.label}
                    options={options}
                    defaultSelectedKey={editColumnsDefaultValues[editRowColumn.key]}
                    onChange={(
                      event: React.FormEvent<HTMLDivElement>,
                      option?: IDropdownOption<any> | undefined,
                      index?: number | undefined
                    ) => { onChangeStatus(event, option, index); 
                           
                    }
                    }
                    placeholder={"Select " + editRowColumn.label}
                    required={false}
                    defaultValue={editColumnsDefaultValues[editRowColumn.key] }
                    disabled={isEditMode}
                />
                </div></>
            );
        }
        break;  
       default:
      }      
    }

    return <>
    {/* Intake Edit section  */}
     <div  style ={{gridColumn: 'span 3'}}>
        <Accordion title="Intake Data" isExpandDefault={true}>       
        <div style={{ display: "grid", gridTemplateColumns: "48% 48%", gridGap: "1rem", gridColumn: "span 2"  }}>
        {tmpRenderObj}
        </div>  
        <div style={{paddingTop:20}}>
        {tmpTableObj}
        </div>    
            {/* Discussion section  */}
       <div style={{ padding: 1,width:"99%" }}>
        <IntakeDiscussions discussionId={editColumnsDefaultValues!=undefined ? editColumnsDefaultValues.Id:0} discussionType="IntakeManagement" onValidDiscussionData={onValidDiscussionData} isExpand={true} ></IntakeDiscussions>
      </div>
      <Stack horizontal styles={{ root: {  justifyContent: 'flex-end'}}} tokens={{childrenGap: 10}}>
      <PrimaryButton text="Save" onClick={handleOnPanelSave} disabled={isEditMode || disableSaveButton()} />
      <DefaultButton text="Cancel" onClick={handleOnPanelXClose} />
    </Stack>
      </Accordion>
      </div>

    <div  style ={{gridColumn: 'span 3'}}>
   
      {/* create POR section  */}
        <Accordion title="POR Details" isExpandDefault={true}> 
            <div >

           { !isEmpty(linkedPorIds) && !isEditMode && 
              <div>
           <MessageBar messageBarType={MessageBarType.info}>
             
              Source ID is already associated with the following Dome POR: 
              {linkedPorIds.map((id: any, index: number) => (
                <span key={id}>
                  <Link
                    target="_blank"
                    onClick={(event) => {
                      event.preventDefault(); // Prevents the default link behavior
                      window.open(`/PORDetails/${id}`, '_blank');
                    }}
                  >
                    {id}
                  </Link>
                  {index === linkedPorIds.length - 1 ? '' : ','}&nbsp;
                </span>
              ))}
             
          </MessageBar>
              </div>
             }
        <Stack tokens={{ childrenGap: 10 }} >
              <ChoiceGroup
                selectedKey={selectedOption}
                options={associateoptions}
                onChange={(ev, option) => {
                  setSelectedOption(option?.key || '');
              
                }}
                disabled={isEditMode}
                styles={{
                  flexContainer: {
                    display: "flex"
                  },
                }}
              />
            </Stack>
          </div>
          
          {/* Associate POR */}
          {selectedOption == 'AssociatePOR' && <div>
          <div style={{ paddingTop: 10, display: "grid", gridTemplateColumns: "48% 48%", gridGap: "1rem", gridColumn: "span 2"  }}>                      
            <div>
            <Label required={true}>Associate POR ID</Label>
            <TagPicker  
                    selectedItems={!isEmpty(filteredDOMEIDValue.DOMEID) ? [{ name: filteredDOMEIDValue.DOMEID, key: filteredDOMEIDValue.DOMEID }]:[]}
                     removeButtonAriaLabel="Remove"
                     selectionAriaLabel={"Selected "+ (filteredDOMEIDValue!=undefined && filteredDOMEIDValue.text)? filteredDOMEIDValue.text:''}
                     inputProps={{ 
                      'aria-label': 'Search by PORID',
                       placeholder:"Search by PORID"
                   }}
                     onResolveSuggestions={(filterText, tagList) => filterSuggestedTags(filterText, tagList, PorIdList)}  
                     onChange={(items:any) => { 
                      
                        if(items.length>0){  
                            const newFilterValues = { ...filteredDOMEIDValue, "DOMEID": items[0].name };
                            setFilteredDOMEIDValue(newFilterValues);
                            setDisableAssociate(false);
                           } 
                           else{
                            const newFilterValues = { ...filteredDOMEIDValue, "DOMEID": "" };
                            setFilteredDOMEIDValue(newFilterValues);
                            setDisableAssociate(true);
                           }
                        }  
                     }
                      getTextFromItem={getTextFromItem}
                     pickerSuggestionsProps={pickerSuggestionsProps}
                     itemLimit={1}
                     // this option tells the picker's callout to render inline instead of in a new layer
                     //pickerCalloutProps={{ doNotLayer: true, calloutMaxWidth: 350 }} 
                   /> 
            </div>
          <div>
          <Stack horizontal styles={{ root: { paddingTop:28}}}>
            <PrimaryButton text="Associate POR" disabled = {disableAssociate}
              onClick={handleOnPanelAssociatePOR} />
          </Stack>

            </div>
          </div>
          </div>}
          

          {/* create por */}
          {selectedOption == 'CreatePOR' && <>
          <div style={{ paddingTop: 10, display: "grid", gridTemplateColumns: "48% 48%", gridGap: "1rem", gridColumn: "span 2"  }}>           
          {isEditMode && <div>          
            <Label >Primary Contact
                <Label className="readonyLabel" style={{ height:'30px',margin:'5px 0px 0px 0px' }} disabled>{editColumnsDefaultValues.PrimaryContactUserName}</Label>
            </Label>
          </div>
          }
            {isEditMode && <div style={{marginTop:'8px'}}>
            <Label >DOME ID</Label>
            <Link
                    target="_blank"
                    onClick={(event) => {
                      event.preventDefault(); // Prevents the default link behavior
                      window.open(`/PORDetails/${editColumnsDefaultValues.DOMEID}`, '_blank');
                    }}
                  > 
              {editColumnsDefaultValues.DOMEID}
            </Link> 
          </div>
          }
               
          {!isEditMode &&  <div style={{gridColumn: "span 2"}}><Label required={true}>Dome Title</Label>
            <TextField 
                defaultValue={editColumnsDefaultValues.Title} 
                resizable={false}
                onChange={(event:any) => { onChangePORTitle(event, event.target); }}
                disabled={isEditMode}
            /></div> }

            {!isEditMode &&  <div><Label required={true}>Target Completion Date</Label>
                <DatePicker
                  value={editColumnsDefaultValues.TargetCompletionDate ? new Date(editColumnsDefaultValues.TargetCompletionDate) : undefined}
                  minDate={new Date()}      
                  placeholder={"Select a target completion date"}
                  onSelectDate={newDate => { 
                    setIsDisabledSaveButton(false);
                    handleDateSelection(newDate); }}
                  formatDate={onFormatDate} 
                textField={{ errorMessage: "" }}
                disabled={isEditMode}
                ></DatePicker>
                </div>  }

            {!isEditMode && <div style={{ height: '300px', gridColumn: 'span 2',paddingBottom:20}}>
              <Label>Description<span style={{paddingLeft:4, color:'#b74c41'}}>*</span></Label>
              <RichTextEditor
                characterMax={4000}
                label=""  
                value={description}
                onBlur={function noRefCheck() { }}
                onChange={handleRichTextChange}
                onFocus={function noRefCheck() { }}
                onInvalid={function noRefCheck() { }}
                onValid={function noRefCheck() { }}
                placeholder="Please enter the Description "
                resizable={true}
                plugins={getPlugins()}
                disabled={isEditMode}
              />
              {onSaveDataValid  && (editColumnsDefaultValues.Description === undefined || editColumnsDefaultValues.Description ==='') ? <span  style={{color: 'rgb(164, 38, 44)', fontSize: '12px', fontWeight: 400, paddingTop:5}}>Please enter the Description.</span>:undefined}
              </div>
              }

          {/* {!isEditMode &&  <Dropdown
              label="POR Classification"
              options={catalogCategoryList}
              defaultSelectedKey={editColumnsDefaultValues.PORClassification}
              disabled={isEditMode}
              onChange={(
                event: React.FormEvent<HTMLDivElement>,
                option?: IDropdownOption<any> | undefined,
                index?: number | undefined
              ) => {
                onChangePORClassification(event, option, index);
                
              }
              }
              placeholder={"Select POR Classification"}
              required={true}
              defaultValue={editColumnsDefaultValues.PORClassification}
              errorMessage={onSaveDataValid && editColumnsDefaultValues.PORClassification === undefined ? 'Please enter the POR Classification' : undefined}
        
             /> } */}

            
        {!isEditMode && <PrimaryContactPicker 
                 label={"Primary Contact"} 
                 onSaveDataValid={onSaveDataValid} 
                 defaultPrimaryContact={editColumnsDefaultValues.PrimaryContact} 
                 onSelectPrimaryContact={onSelectPrimaryContact} 
                 required={primaryContactRequired}></PrimaryContactPicker> }
        
        {!isEditMode && 
          <div><Label required>Service Line</Label>
          <Label className="readonyLabel" disabled>{editColumnsDefaultValues.ServiceLineName}</Label>
          </div>
          
      }

        {!isEditMode &&  <Dropdown
              label="Category"
              options={catalogCategoryList}
              defaultSelectedKey={editColumnsDefaultValues.Category}
              disabled={isEditMode}
              onChange={(
                event: React.FormEvent<HTMLDivElement>,
                option?: IDropdownOption<any> | undefined,
                index?: number | undefined
              ) => {
                onChangeCategory(event, option, index);
               
              }
              }
              placeholder={"Select Category"}
              required = {isEmpty(catalogCategoryList) ? false : true}
              defaultValue={editColumnsDefaultValues.Category}
              errorMessage={onSaveDataValid && showCategoryErrorMessage ? 'Please enter the Category' : undefined}
          /> }

          {!isEditMode && (!isEmpty(subCategoryList) &&  !(subCategoryList.length == 1 && subCategoryList.filter((x: any) => x.text === "n/a").length > 0 )) && <Dropdown
              label="Sub Category"
              disabled={isEditMode}
              options={subCategoryList ?? []}
              defaultSelectedKey={editColumnsDefaultValues.SubCategory}
              onChange={(ev, selected) => { onChangeSubCategory(ev, selected);                                          
              }}
              placeholder={"Select Sub Category"}
              required = {isEmpty(subCategoryList) ? false : true}
              errorMessage={onSaveDataValid && showSubCategoryErrorMessage ? 'Please enter the Sub Category' : undefined}
              defaultValue={editColumnsDefaultValues.SubCategory}
          />}

          {!isEditMode && !isEmpty(patternsList) &&
           <Dropdown
           label="Pattern"
           options={patternsList}
           defaultSelectedKey={editColumnsDefaultValues.Pattern}
           onChange={(
             event: React.FormEvent<HTMLDivElement>,
             option?: IDropdownOption<any> | undefined,
             index?: number | undefined
           ) => {onChangePattern(event, option, index);
            } 
           }
           placeholder={"Select a Pattern "}
           required={isEmpty(patternsList) ? false : true}
           defaultValue={editColumnsDefaultValues.Pattern}
           errorMessage={onSaveDataValid && showPatternErrorMessage? 'Please enter the Pattern' : undefined}
       />
          }

          {!isEditMode &&  !isEmpty(capabilityList) &&
            <Dropdown
            label="Capability"
            options={capabilityList}
            defaultSelectedKey={editColumnsDefaultValues.Capability}
            onChange={(
              event: React.FormEvent<HTMLDivElement>,
              option?: IDropdownOption<any> | undefined,
              index?: number | undefined
            ) => {onChangeCapability(event, option, index);
             }
            }
            placeholder={"Select a Capability "}
            required={isEmpty(capabilityList) ? false : true}
            defaultValue={editColumnsDefaultValues.Capability}
            errorMessage={onSaveDataValid && showCapabilityErrorMessage ? 'Please enter the Capability' : undefined}     
        />
          }      

        
                 </div>       
        <Stack horizontal styles={{ root: {  justifyContent: 'flex-end',paddingTop:10}}}>
        <PrimaryButton text="Create POR"  disabled={disableCreatePORButon} 
          onClick={handleOnPanelCreatePOR} />
    </Stack> 
        </> }

       

      </Accordion>
      </div>
    </>
    
  }

  

  //pattern related code start 
  const [catalogCategoryList, setCatalogCategoryList] = useState<any>([]);
  const [patternsCombinationList, setPatternsCombinationList] = useState<any>([]);
  const [subCategoryList, setSubCategoryList] = useState<any>([]);
  const [patternsList, setPatternList] = useState<any>([]);
  const [capabilityList, setCapabilityList] = useState<any>([]);
  const [referenceDetailList, setReferenceDetailList] = useState<any>([]);
  const [contactTypeList, setContactTypeList] = useState<any>([]);
  function uniquearray(arr: any) {
    return arr.filter((obj: any, index: any, self: any) => index === self.findIndex((o: any) => o["key"] === obj["key"]));
  }

  const LoadPORCatlogCategoryDetails = () => {
    DOMEApiHelper('PORDetails/GetAllPORDetailsDataAsync', requestOptionsGETALL)
      .then(res => {
        setIsLoaded(false);
        if (!isEmpty(res)) {
          setReferenceDetailList([
            ...res[0]["ReferenceDetailList"].map((item: any) => ({
              key: item.Id,
              text: item.Title,
              ServiceLine: item.ServiceLine,
            })),         
          ]);
          setContactTypeList([
            ...res[0]["ContactTypeList"].map((item: any) => ({
              key: item.Id,
              text: item.Title,
              ServiceLine: item.ServiceLine,
            }))
          ]);
        }
      }).catch(error => {
        setIsLoaded(false);
        setToastTitle("Error while fetching Pattern data  ");
        setToastMessage(error.toString());
        setToastType(CoherenceNotificationType.ERROR);
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 5000);
      });

  }

  const onValidDiscussionData = (discussionData: any, discussion: any) => {
    setDiscussionData(discussionData);
}


  //Pivot related code end 
 
    const resetSelection = (): any => {
      setIsSelectedAll(false); 
      IntakeManagementData.forEach((element: any) => {
          element.isSelected = false;
      });
      setIntakeManagementData([...IntakeManagementData]);
  }
    const resetPagination = () => {
        setRefreshPagination(refreshPagination ? false : true);
      } 
      const updatePaginationData = (data: any) => {
        if (activeTab === NotificationPivotOptions.ALLITEMS) {
          setIntakePaginatedData(data);
        }
        else if (activeTab === NotificationPivotOptions.REVIEWPENDING) {
          setIntakePaginatedData(data.filter((data: any) => isEmpty(data.Status) || data.Status== 'To be Reviewed' || data.Status=='Review In Progress'));        
        }
        else if (activeTab === NotificationPivotOptions.REVIEWCOMPLETED) {
          setIntakePaginatedData(data.filter((data: any) => data.Status=='Review Completed'  || data.Status =='No DOME WorkFlow'));
        }
    }


    const fetchPORIdbyReferenceValue= (item: any) => { 
      if(!isEmpty(item.SourceID)){
        const requestOptions = {
          method: 'Get',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors'
        }; 
        DOMEApiHelper('IntakeManagement/GetPORIdByReference?ReferenceType='+ item.Source +'&ReferenceValue='+ item.SourceID + '&ServiceLineId=' + item.ServiceLineId, requestOptions)
      .then(res => {
        if(res.length !==0){
        setLinkedPorIds(res);
        }
      }).catch(error => {
        setIsLoaded(false);
        setToastTitle("Error while fetching Porids linked to the source id");
        setToastMessage(error.toString());
        setToastType(CoherenceNotificationType.ERROR);
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 5000);
      });

      }
    }


    const onClickGridAction= (item: any) => {
      item.isPatternRequired=false;
      setSelectedOption("CreatePOR");
      setFilteredDOMEIDValue([]);
      setDisableAssociate(true);
      setLinkedPorIds([]);
      setSubCategoryList([]);
      setPatternList([]);
      setCapabilityList([]);
      setIsDisabledSaveButton(true); // To disable Save button
      setDisableCreatePORButon(true); // To disable Create POR button 
      if(item.Status !== 'Review Completed' || item.Status !== 'No DOME WorkFlow'){
        LoadPatterns(item.ServiceLineId, item);
      }
      if(item.Status != 'No DOME WorkFlow' && (item.DOMEID == undefined || item.DOMEID == '' || item.DOMEID == null )){
        setIsEditMode(false);
      }
      else{
        setIsEditMode(true);
      } 

      

      //Enable edit if Data Monitoring Issue id is empty 
      if (!isEmpty(item.SourceID)) {
          setDescription(item.Description);
          item.isEditMode = 'true'; 
          if( item.Status == "To be Reviewed" || item.Status == "Review In Progress"){          
          fetchPORIdbyReferenceValue(item);
          item.isEditMode = 'true'; 
          seteditColumnsDefaultValues(item);
         // updatePORClassificationAndCategory(item);   
         setshowEditGridPanel(true);
          
          }
          else{
            item.isEditMode = 'true'; 
            seteditColumnsDefaultValues(item);
            setshowEditGridPanel(true);
          }

      }
            
      if(!isEmpty(item.ReferenceID)){
        // Replace single quotes with double quotes and 'None' with 'null'
        const correctedJsonString = item.ReferenceID.replace(/'/g, '"').replace(/None/g, 'null');

        // Parse the corrected JSON string into an object
        const jsonObject = JSON.parse(correctedJsonString); 
       // Remove attributes with null values
        const newJsonObject = Object.fromEntries(
          Object.entries(jsonObject).filter(([key, value]) => value !== null && value !== "null")
        );
      

      if (!isEmpty(newJsonObject)) { 
        setRefIDsData(newJsonObject);
      }
      }else{ 
        setRefIDsData([]);
      }
      if(!isEmpty(item.RuleRecommendationsList)){ 
        setRulesRecommendation(item.RuleRecommendationsList); 
      }
      else {
        setRulesRecommendation([]);
      }
      setOriginalEditData(item);//this is to store the original data for comparison
    }
    const getColumns = (): CoherenceDataGridColumn<any>[] => {
        var advancedColumns: CoherenceDataGridColumn<any>[] = [ 
            {
              key: 'Actions',
              name: 'Action',
              fieldName: 'Action',
              type: 'string',
              isResizable: false,  
              minColumnWidth: 50,
              maxWidth: 50, 
              data: [],
              onRender: (item: any) => {
                    switch (item.Status) {
                      case 'Review In Progress':
                        case 'To be Reviewed':
                        {
                        return <IconButton onClick={() => {onClickGridAction(item)}}  style={{cursor:'pointer',width:'20px',height:'20px'}} 
                        iconProps={{ iconName: 'Edit'}}
                    /> 
                        }
                      case 'Review Completed':
                      case 'No DOME WorkFlow':
                        {
                        return <IconButton onClick={() => {onClickGridAction(item)}} style={{cursor:'pointer',width:'20px',height:'20px'}}
                        iconProps={{ iconName: 'EntryView'}}
                    /> 
                        } 
                    }
                  }, 
              iconClassName: "customSortIcon"
            },         
            {
              key: 'Source',
              name: 'Source',
              fieldName: 'Source',
              type: 'string',
              isResizable: true,
              minColumnWidth: 90,
              maxWidth: 90,
              ariaLabel: 'Source',
              data: [],
              onRender: (item: any) => {
                return <TooltipHost content={item.Source}>
                  <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)', textAlign: 'left' }}>
                    {item.Source}</span>
                </TooltipHost>
              },
              iconName: appendSortIcon('Source'),
              iconClassName: "customSortIcon"
            },
            {
              key: 'SourceID',
              name: 'Source ID',
              fieldName: 'SourceID',
              type: 'link',
              url: 'url',
              isResizable: true,
              minColumnWidth: 70,
              maxWidth: 70,
              ariaLabel: 'Source ID',
              data: [],
              onRender: (item: any) => {
                return <Link href={item.SourceLink} target="_blank">
                {item.SourceID}
              </Link>
              },
              iconName: appendSortIcon('SourceID'),
              iconClassName: "customSortIcon"
            },
            {
              key: 'Status',
              name: 'Status',
              fieldName: 'Status',
              type: 'string',
              isResizable: true,
              minColumnWidth: 140,
              maxWidth: 140,
              ariaLabel: 'Status',
              data: [],
              onRender: (item: any) => {
                  return <TooltipHost content={item.Status}>
                      <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
                          {item.Status}</span>
                  </TooltipHost>
              },
              iconName: appendSortIcon('Status'),
              iconClassName: "customSortIcon"
            },
            {
                key: 'DomeId',
                name: 'DOME ID',
                fieldName: 'DOMEID',
                type: 'string',
                isResizable: true,
                minColumnWidth: 90,
                maxWidth: 90,
                ariaLabel: 'DOME ID',
                data: [],
                onRender: (item: any) => {
                    return <TooltipHost content={item.DOMEID}>
                      <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
                        <Link onClick={(event) => { 
                          window.open(`/PORDetails/${item.DOMEID}`, '_blank');
                        }}>{item.DOMEID}</Link>
                      </span>
                    </TooltipHost>
                },
                iconName: appendSortIcon('DOMEID'),
                iconClassName: "customSortIcon"
            },
            {
                key: 'Title',
                name: 'Title',
                fieldName: 'Title',
                type: 'string',
                isResizable: true,
                minColumnWidth: 260,
                maxWidth: 260,
                ariaLabel: 'Title',
                data: [],
                onRender: (item: any) => {
                    return <TooltipHost content={item.Title}>
                        <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
                            {item.Title}</span>
                    </TooltipHost>
                },
                iconName: appendSortIcon('Title'),
                iconClassName: "customSortIcon"
             }, 
          {
            key: 'TargetLaunchDate',
            name: 'Target Launch Date',
            fieldName: 'TargetCompletionDate',
            type: 'string',
            isResizable: true,
            minColumnWidth: 160,
            maxWidth: 160,
            ariaLabel: 'Target Launch Date',
            data: [],
            onRender: (item: any) => {
                return <TooltipHost content={item.TargetCompletionDate}>
                    <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}> 
                        {item.TargetCompletionDate}
                        </span>
                </TooltipHost>
            },
            iconName: appendSortIcon('TargetCompletionDate'),
            iconClassName: "customSortIcon"
        },
        {
          key: 'ServiceLine',
          name: 'Service Line',
          fieldName: 'Service Line',
          type: 'string',
          isResizable: true,
          minColumnWidth: 260,
          maxWidth: 260,
          ariaLabel: 'Service Line',
          data: [],
          onRender: (item: any) => {
              return <TooltipHost content={item.Title}>
                  <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
                      {item.ServiceLineName}</span>
              </TooltipHost>
          },
          iconName: appendSortIcon('Title'),
          iconClassName: "customSortIcon"
        }, 
        {
          key: 'ReferenceDetails',
          name: 'Reference Details',
          fieldName: 'ReferenceDetails',
          type: 'link',
          url: 'url',
          isResizable: true,
          minColumnWidth: 110,
          maxWidth:110,
          ariaLabel: 'Reference Details',
          data: [],
          onRender: (item: any) => {
            return <Link onClick={(event) => { openModal(item) }} >
              {'Reference Details'}
            </Link>
          }
        }, 
        {
          key: 'RulesRecommendation',
          name: 'Rules Recommendation',
          fieldName: 'RulesRecommendation',
          type: 'string',
          isResizable: true,
          minColumnWidth: 180,
          maxWidth: 180,
          ariaLabel: 'Rules Recommendation',
          data: [],
          onRender: (item: any) => {
            return (
                <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
                    {!isEmpty(item.RuleRecommendationsList) ? 
                        item.RuleRecommendationsList
                            .sort((a: any, b: any) => {
                              if(!isEmpty(a["Suggestion Created Date"])){
                                const dateA = new Date(a["Suggestion Created Date"]).getTime();
                                const dateB = new Date(b["Suggestion Created Date"]).getTime();
                                return Math.abs(dateA - Date.now()) - Math.abs(dateB - Date.now()); 
                              }
                            })
                            [0]["Rules Recommendation"] 
                        : '-'}
                </span>
            );
        },
            iconName: appendSortIcon('RulesRecommendation'),
            iconClassName: "customSortIcon"
        },
        {
          key: 'PrimaryContact',
          name: 'Primary Contact',
          fieldName: 'PrimaryContactUserName',
          type: 'string',
          isResizable: true,
          minColumnWidth: 180,
          maxWidth: 180,
          ariaLabel: 'PrimaryContact',
          data: [],
          onRender: (item: any) => {
              return <TooltipHost content={item.PrimaryContactUserName}>
                  <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
                      {item.PrimaryContactUserName}</span>
              </TooltipHost>
            },
            iconName: appendSortIcon('PrimaryContactUserName'),
            iconClassName: "customSortIcon"
        } 
        ]; 
        return advancedColumns;
    };

     // disable save button 
    
const disableSaveButton = (): boolean => {  
  let disableSave=true;
  if(!isEmpty(discussionData) && !isEmpty(discussionData.Discussion)){
    disableSave=false;
  }
  if(originalEditData.Status !=editColumnsDefaultValues.Status){
    disableSave=false;
  }
  return disableSave; 
} 

const disablePORSaveButton= ()=> {     
    if(isEmpty(editColumnsDefaultValues.Title) || isEmpty(editColumnsDefaultValues.PrimaryContact) || isEmpty(editColumnsDefaultValues.Description) || isEmpty(editColumnsDefaultValues.TargetCompletionDate) || isEmpty(editColumnsDefaultValues.Category) || isEmpty(editColumnsDefaultValues.SubCategory)|| isEmpty(editColumnsDefaultValues.Pattern)|| isEmpty(editColumnsDefaultValues.Capability)){
      setDisableCreatePORButon(true); 
      }
      else{
        setDisableCreatePORButon(false);
      }   
}
    //Sort code start
    const [sortColumnDetails, setSortColumnDetails] = useState<any>({ "columnName": "TargetCompletionDate", "sortType": "asc" });

    const createSortColumns = () => {
        return [
            { "fieldName": "Source", "label": "Source" },
            { "fieldName": "SourceID", "label": "Source ID" },
            { "fieldName": "TargetCompletionDate", "label": "Target Launch Date" }
        ]
    };

    const sortDefault = (data: any) => {
        if (sortColumnDetails !== undefined && sortColumnDetails["columnName"] !== undefined) {
            onSorted(sortColumnDetails["columnName"], sortColumnDetails["sortType"], data);
        }
    }
    const appendSortIcon = (columnName: string): any => {
        if (sortColumnDetails !== undefined && sortColumnDetails["columnName"] === columnName) {
            return sortColumnDetails["sortType"] === "asc" ? "Ascending" : "Descending";
        }
    }

    const onSorted = (columnName: string, sortType: string, intakeManagementData?: any): void => {
        resetSelection();
        setSortColumnDetails({ "columnName": columnName, "sortType": sortType });
        let sortedData = (intakeManagementData !== undefined ? intakeManagementData : IntakeManagementData).sort((a: any, b: any) => {
            if (a[columnName] === null) {
              return sortType === 'asc' ? 1 : -1;
            }
            if (b[columnName] === null) {
                return sortType === 'asc' ? -1 : 1;
            }

             // Handle date values
              if (Date.parse(a[columnName]) && Date.parse(b[columnName])) {
                const dateA = new Date(a[columnName]);
                const dateB = new Date(b[columnName]);
                if (dateA> dateB) {
                  return sortType === 'asc' ? 1 : -1;
                }
                if (dateA < dateB) {
                  return sortType === 'asc' ? -1 : 1;
                } 
            }             
            if (a[columnName] > b[columnName]) {
              return sortType === 'asc' ? 1 : -1;
            }
            if (a[columnName] < b[columnName]) {
              return sortType === 'asc' ? -1 : 1;
            }
            return 0;
        });

        setIntakeManagementData(JSON.parse(JSON.stringify(sortedData)));
        resetPagination();
    }

    //Sort code end

    const requestOptionsGETALL = {
        method: 'GET'
    };
    const LoadIntakeManagementData = (text: string) => {
        setLoadText(text);
        setIsLoaded(true);
        setIntakeManagementData([]); 
        setActiveTab(NotificationPivotOptions.REVIEWPENDING);        
        DOMEApiHelper('IntakeManagement', requestOptionsGETALL)
            .then((res: any) => { 

                // Assuming `res` is the array of nested objects
                let simplifiedObjects = res.map((item: any) => {
                  let ruleRecommendationsList=[];
                  if(!isEmpty(item.RulesRecommendation)){
                    ruleRecommendationsList = JSON.parse(item.RulesRecommendation);  
                  } 
                  let sourceChangesList = [];
                  if(!isEmpty(item.SourceChanges)){
                    sourceChangesList = JSON.parse(item.SourceChanges);
                  }

                  return {
                    Id: item.Id,
                    Source: item.Source,
                    SourceID: item.SourceID,
                    Title: item.SourceTitle,
                    ReferenceID: item.ReferenceID,
                    ReferenceDetails: JSON.parse(item.ReferenceDetails), 
                    ReferenceSourceContact: item.ReferenceSourceContact,
                    SourceLink: item.SourceLink.replaceAll('\\', ''),
                    RuleRecommendationsList: ruleRecommendationsList,  
                    MLRecommendation: !isEmpty(item.MLRecommendation) ? JSON.parse(item.MLRecommendation) : null, 
                    Status: item.Status,
                    RequestedBy: !isEmpty(item.ReferenceSourceContact) ? JSON.parse(item.ReferenceSourceContact)['Launch Leader'] : '',
                    RequestedByUserName: item.RequestedByUserName,  
                    TargetCompletionDate: !isEmpty(JSON.parse(item.ReferenceDetails)?.["Target Launch Date"]) && dayjs(JSON.parse(item.ReferenceDetails)?.["Target Launch Date"]).toString() !== 'Invalid Date' ? dayjs(JSON.parse(item.ReferenceDetails)?.["Target Launch Date"], { format: 'YYYY-MM-DD HH:mm:ss' }).format('MM/DD/YYYY') : "",
                    Description: JSON.parse(item.ReferenceDetails).Description !== 'null' ? JSON.parse(item.ReferenceDetails).Description : null,
                    SourcePrimaryContact: null,
                    DOMEID: item.DOMEID,
                    PrimaryContact: item.MLSuggestedPLLaunchLead !== "" ? [{ "objectId": item.MLSuggestedPLLaunchLead, "text": item.MLSuggestedPLLaunchLeadUserName }] : [],
                    PrimaryContactUserName: item.PrimaryContactUserName,
                    SourceChangesList: sourceChangesList,
                    ServiceLineId: item.ServiceLineId,
                    ServiceLineName: item.ServiceLineName,
                  };
                          
      }); 
           //bydefault show review pending data.
           let intakeManagementReviewPendingData = simplifiedObjects.filter((data: any) => isEmpty(data.Status) || data.Status=='To be Reviewed' || data.Status=='Review In Progress');
           setIntakeManagementData(intakeManagementReviewPendingData);
           setIntakeItemsBeforeFilterData(simplifiedObjects); //store data to store before filter  
           //sort bydefault 
           if (sortColumnDetails !== undefined && sortColumnDetails["columnName"] !== undefined) {
            sortDefault(intakeManagementReviewPendingData);
        }
        else {
            resetPagination();
        }
        setIsLoaded(false);
        setLoadText("");
                
    }).catch((error: { toString: () => React.SetStateAction<string>; }) => {
        setLoadText("");
        setIsLoaded(false);
        setToastTitle("Error while fetching intake management data  ");
        setToastMessage(error.toString());
        setToastType(CoherenceNotificationType.ERROR);
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 5000);
    });
};


// Helper function to safely parse JSON
function parseJson(jsonString: string) {
  try {
      return JSON.parse(jsonString);
  } catch (error) {
      
      // Handle the error as needed, e.g., return a default value
      return null;
  }
}
    useEffect(() => {
        setIsLoaded(true);
        LoadIntakeManagementData("Loading data")
        LoadPORCatlogCategoryDetails();
        LoadPorlist();
        // eslint-disable-next-line
    }, [setIntakeManagementData]);

//Filter code start  
//const [intakeIssueBeforeFilterData, setIntakeIssueBeforeFilterData] = useState<any>([]);
const [intakeFilteredData, setIntakeFilteredData] = useState<any>([]);
const [intakeItemsBeforeFilterData, setIntakeItemsBeforeFilterData] = useState<any>([]);
const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
const onApplyFilter = (filteredArray: any) => {
    resetSelection();
    setIsFilterApplied(true);
    setIntakeFilteredData(JSON.parse(JSON.stringify(filteredArray)));
    if (activeTab === NotificationPivotOptions.ALLITEMS) {
      setIntakeManagementData(filteredArray);
    }
    else if (activeTab === NotificationPivotOptions.REVIEWPENDING) {
      setIntakeManagementData(filteredArray.filter((data: any) => isEmpty(data.Status) || data.Status=='To be Reviewed' || data.Status=='Review In Progress'));
    }
    else if (activeTab === NotificationPivotOptions.REVIEWCOMPLETED) {
      setIntakeManagementData(filteredArray.filter((data: any) => data.Status=='Review Completed' || data.Status =='No DOME WorkFlow'));
    }
    resetPagination();
};

const onClearFilter = () => {
    setIsFilterApplied(false);
    setIntakeFilteredData([]);
    if (activeTab === NotificationPivotOptions.ALLITEMS) {
        setIntakeManagementData(intakeItemsBeforeFilterData);
    }
    else if (activeTab === NotificationPivotOptions.REVIEWPENDING) {
      setIntakeManagementData(intakeItemsBeforeFilterData.filter((data: any) => isEmpty(data.Status) || data.Status=='To be Reviewed' || data.Status=='Review In Progress'));
    }
    else if (activeTab === NotificationPivotOptions.REVIEWCOMPLETED) {
      setIntakeManagementData(intakeItemsBeforeFilterData.filter((data: any) => data.Status=='Review Completed'  || data.Status =='No DOME WorkFlow'));
    }
    resetPagination();
}

const createFilterColumns = () => {
    return [
        { "fieldName": "Source", "label": "Source", "type": "dropdown", "options": [] },
        { "fieldName": "SourceID", "label": "Source ID", "type": "searchTextBox", "options": [] },
        { "fieldName": "Status", "label": "Status", "type": "dropdown", "options": [] },
        { "fieldName": "ServiceLineName", "label": "Service Line", "type": "multiselectDropdown", "options": [], "Default":["VL-PECO"] },
        {"fieldName":"TargetCompletionDate","label":"Target Launch Date","type":"date", "fromTargetCompletionDate": null,"toTargetCompletionDate":null }
     ];
}
//Filter code end

//associate check for id
const searchporid = () => {
  return [
      { "fieldName": "PorID", "label": "POR ID", "type": "searchTextBox", "options": [] },
   ];
}

  return (
    <>
    <div  style={{ 
    minHeight: '100vh' //this added to adjust footer to screen height
  }}
> 
        <h2>Intake Management</h2>

  <Accordion title="Intake Items" isExpandDefault={true}>
  <CustomGridControls  defaultFilterSessionKey="IntakeManagementDefaultFilter" filterColumns={createFilterColumns()} sortColumns={createSortColumns()} data={intakeItemsBeforeFilterData} onApplyFilter={onApplyFilter} onSorted={onSorted} onClearFilter={onClearFilter} />
 
  {showToast && <ToastMessage showToast={showToast} type={toastType} message={toastMessage} title={toastTitle}  ></ToastMessage>}
  <Pivot onLinkClick={handlePivotChange} selectedKey={activeTab}>
    <PivotItem
      headerText={"All Items (" + getDataCountByActionType(NotificationPivotOptions.ALLITEMS) + ")"}
      itemKey={NotificationPivotOptions.ALLITEMS}
      ariaLabel="All Items"
    >
      <div style={{ minHeight: 425, height: 425, maxHeight: 425 }}>
      {!isLoaded ?<CoherenceDataGrid
          listConfig={getColumns()}
          data={intakePaginatedData}
          isScrollable={true}
          isSortable={false}
          sortByDefault={false}
          layoutMode={DetailsListLayoutMode.fixedColumns}
          ariaLabel={"Intake Management All Items List"}
        ></CoherenceDataGrid>
           : <CoherenceLoading primaryText={LoadText}></CoherenceLoading>} 
      </div>
    </PivotItem>
    <PivotItem
                    headerText={"Review Pending (" + getDataCountByActionType(NotificationPivotOptions.REVIEWPENDING) + ")"
                    }
                    itemKey={NotificationPivotOptions.REVIEWPENDING}
                    ariaLabel="Review Pending"
                >
                    <div style={{ minHeight: 425, height: 425, maxHeight: 425 }}>
                        {!isLoaded ? <CoherenceDataGrid listConfig={getColumns()}
                            data={intakePaginatedData}
                            isScrollable={true}
                            ariaLabelForSelectAllCheckbox={'select all checkbox'}
                            isSortable={false}
                            sortByDefault={false}
                            selectionPreservedOnEmptyClick={true}
                            layoutMode={DetailsListLayoutMode.fixedColumns}
                            ariaLabel={"Intake Management Reivew Pending List"}
                        ></CoherenceDataGrid>
                            : <CoherenceLoading primaryText={LoadText}></CoherenceLoading>}
                    </div>
                </PivotItem>
                <PivotItem
                    headerText={"Review Completed (" + getDataCountByActionType(NotificationPivotOptions.REVIEWCOMPLETED) + ")"}
                    itemKey={NotificationPivotOptions.REVIEWCOMPLETED}
                    ariaLabel="Review Completed"
                >
                    <div style={{ minHeight: 425, height: 425, maxHeight: 425 }}>
                        {!isLoaded ?
                            <CoherenceDataGrid
                                listConfig={getColumns()}
                                data={intakePaginatedData}
                                isScrollable={true}
                                ariaLabelForSelectAllCheckbox={'select all checkbox'}
                                isSortable={false}
                                sortByDefault={false}
                                layoutMode={DetailsListLayoutMode.fixedColumns}
                                ariaLabel={"Intake Management Review Completed List"}
                            ></CoherenceDataGrid>
                            : <CoherenceLoading primaryText={LoadText}></CoherenceLoading>}
                    </div>
                </PivotItem>
  </Pivot>
  <CustomPagination refreshPagination={refreshPagination} updatePaginationData={updatePaginationData} data={IntakeManagementData} pageSize={8}></CustomPagination>
  </Accordion>
  <CoherencePanel  
      titleText={"Edit Intake Details"}
      isOpen={showEditGridPanel}
      onDismiss={handleOnPanelXClose}
      panelSize={CoherencePanelSize.large}
      closeButtonAriaLabel="Close"
      onRenderFooter={() => (
        <Stack horizontal >
          {/* <PrimaryButton text="Save" 
            //disabled={disableSaveButton() || isDisabledSaveButton }
          onClick={handleOnPanelSave} disabled={isEditMode}/> 
          <DefaultButton text="Cancel" onClick={handleOnPanelXClose} /> */} 
        </Stack>
      )}
    >
      <>
        <div  style={{ display: "grid", gridTemplateColumns: "30% 30% 30%",gridGap: "1rem" }} >
        {showEditGridPanel && generateControls()}
        </div>
      </> 
    </CoherencePanel>
<CoherencePanel
      title={DataMismatchAttributesTitle}
      titleText={DataMismatchAttributesTitle}
        isOpen={modalOpen}
        onDismiss={handleOnPanelJSONXClose}
        panelSize={CoherencePanelSize.medium}
         >
        {modalOpen && getReferenceDetailsAttributes()}
      </CoherencePanel>
      </div>
    </>
  );
};

export default IntakeManagement;
