import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { msalConfig, IdTokenClaims } from "./authConfig";
import { AccountInfo } from "@azure/msal-browser";
import {CoherenceLoadingHelper} from "./utils/CoherenceLoadingHelper" 
import UnAuthorized from "./shared/UnAuthorized/UnAuthorized";
import { useLocation, useNavigate } from "react-router";
interface RouteGuardProps {
    component :any
    roles?: string[];
}

export const RouteGuard: React.FunctionComponent<RouteGuardProps> = (props: RouteGuardProps) => { 

const { instance } = useMsal();
const [isAuthorized, setIsAuthorized] = useState(false);
const [isLoaded, setIsLoaded] = useState(false);
let navigate = useNavigate(); 
const location = useLocation();
useEffect(() => {
    const currentAccount: AccountInfo = instance.getActiveAccount() as any;
    if (currentAccount) {
        if (currentAccount.tenantId === msalConfig.auth.tenantId) {
            const idTokenClaims = currentAccount.idTokenClaims as IdTokenClaims;
            if (idTokenClaims && idTokenClaims.aud === msalConfig.auth.clientId && idTokenClaims["roles"]) {
                if(props.roles != undefined && props.roles != null && props.roles.length>0){
                    const intersection = props.roles.filter((role: any) => idTokenClaims["roles"].includes(role));
                    if (intersection.length > 0) {  
                        setIsAuthorized(true);
                    }             
                    else {
                        setIsAuthorized(false);
                    }      
                }
                else{
                    setIsAuthorized(true);
                }	  
            }
            setIsLoaded(true);
        }
    }
});

return (<div>
        {isLoaded ? (isAuthorized ? props.component : <UnAuthorized />) : <CoherenceLoadingHelper/>}
    </div>);
};

export default RouteGuard;