import type { EditorPlugin, IEditor, PluginEvent, DOMEventHandler } from 'roosterjs';

export class CustomRTEPlugin implements EditorPlugin {
  protected _editor: IEditor | undefined;

  private readonly _insertCharacterCallback: (character: string) => void;

  constructor(insertCharacterCallback: (character: string) => void) {
    this._insertCharacterCallback = insertCharacterCallback;
    this._editor = undefined;
  }

  getName() {
    return 'CustomRTEPlugin';
  }

  initialize(editor: IEditor) {
    this._editor = editor;
    editor.addDomEventHandler('keydown', this.handleKeyDownEvent as DOMEventHandler);
  }

  dispose() {
    this._editor = undefined;
  }

  onPluginEvent(event: PluginEvent) {}

  private handleKeyDownEvent = (event: KeyboardEvent) => {  
    if (event.shiftKey && event.key === '>') { 
      event.preventDefault(); 
      // insert the given character at the current cursor position
      this._editor?.insertContent('>'); 
    }
  };
}
