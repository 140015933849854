import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider, loadTheme } from '@fluentui/react';
import App from "./App";
import {
  CoherenceTheme,
} from "@coherence-design-system/styles";
// MSAL imports
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { MsalProvider } from "@azure/msal-react";
// Register service worker
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/service-worker.js')
        .then(registration => {
          console.log('Service Worker registered:', registration);
        })
        .catch(error => {
          console.error('Service Worker registration failed:', error);
        });
    });
  }
export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.handleRedirectPromise()
    .then((tokenResponse) => {
        if (!tokenResponse) {
            const accounts = msalInstance.getAllAccounts();
            if (accounts.length === 0) {
                // No user signed in
                msalInstance.loginRedirect({
                    scopes: ["User.Read"]
                  });
            }
        } else {
            // Do something with the tokenResponse
        }
    })
    .catch(err => {
        // Handle error
        //console.error(err);
    });

msalInstance.addEventCallback((event: EventMessage) => {
    if ((event.eventType === EventType.LOGIN_SUCCESS && event.payload) || 
        (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS && event.payload)) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <Router>
        <ThemeProvider  theme={CoherenceTheme}>
          <MsalProvider instance={msalInstance}>
              <App />
          </MsalProvider>
        </ThemeProvider>
    </Router>
);
