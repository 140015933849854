import { PublicClientApplication } from "@azure/msal-browser";
import { CacheLookupPolicy } from "@azure/msal-browser";
import { format } from 'date-fns';

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID as string,
    authority:  process.env.REACT_APP_AUTHORITY as string
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

export async function RMApiHelper(apiurl: string, apioptions: any) {
    apiurl = process.env.REACT_APP_RM_API_HOST_URL + apiurl; 
    const account = msalInstance.getActiveAccount(); // Get current active account

    const accessToken = await acquireToken(account); // fetch the token
    const headers = createHeaders(accessToken, apiurl); // append the token to header
    apioptions.headers = headers;

    return fetchAndHandleResponse(apiurl, apioptions); // API call
}

async function acquireToken(account: any) {
  const tokenRequest = {
    scopes: [process.env.REACT_APP_RM_API_SCOPE] as string[] ,
    account: account
  }; 
  const response = await msalInstance.acquireTokenSilent(tokenRequest);
  return response.accessToken;
}

function createHeaders(accessToken: string, apiurl: string) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    if (!apiurl.includes("PORDetails/CreatePORItem")) {
        headers.append("Content-Type", "application/json");
    }
    return headers;
}

async function fetchAndHandleResponse(apiurl: string, apioptions: any) {
    return fetch(apiurl, apioptions)
        .then((response: any) => {
            if (response.status !== 200) {
                return response.json().then((errorData: any) => {
                    throw new Error(`${JSON.stringify(errorData.message ? errorData.message : errorData.errors)}`);
                });
            } else {
                return response.json();
            }
        })
        .catch(error => {
            if (error instanceof Response) {
                // if the error is a Response object, read the error message from the response
                return error.json().then(errorMessage => {
                    throw new Error(`${errorMessage.message}`);
                });
            } else {
                throw error;
            }
        });
}

export const isEmpty = (value: any) => {
    let isEmpty = false;
    if (value === undefined || value === null || value === '') {
        isEmpty = true;
    } else if (Array.isArray(value) && value.length === 0) {
        isEmpty = true;
    } else if (value instanceof Date && isNaN(value.getTime())) {
        isEmpty = true;
    } else if (typeof value === 'object' && !(value instanceof Date) && Object.keys(value).length === 0) {
        isEmpty = true;
    }
    else if (value === 0) {
        isEmpty = true;
    }
    return isEmpty;
};

export const onFormatDate = (_date?: any) => {
    return format(_date, 'MM/dd/yyyy')
}; 
