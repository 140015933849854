import React, { useState, useEffect } from "react";
import { DOMEApiHelper, isEmpty, onFormatDate } from "../../../../../utils/DOMEApiHelper";
import { Stack, TextField, IStackStyles, IStackProps, DatePicker, Breadcrumb, Dropdown, PrimaryButton, DefaultButton, IDropdownOption, Label, Callout, Link, DetailsListLayoutMode, CommandBar, ICommandBarItemProps, Checkbox } from "@fluentui/react";
import { CoherenceDataGrid, CoherenceDataGridColumn, CoherenceLoading, CoherenceNotificationType, CoherencePanel, CoherencePanelSize } from "@coherence-design-system/controls";
import { useLocation } from "react-router";
import { GetUserRole } from "../../../../../utils/GetUserRole";
import ToastMessage from "../../../../../shared/ToastMessage/ToastMessage";
import { Card, CardStandardHeader } from "@coherence-design-system/card";
interface prop {
  onSaveReferenceDetails: any;
  referenceDetailList: any;
  ServiceLineError: boolean;
  Reset: boolean;
}
export const References = (props: prop) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isEditItem, setIsEditItem] = useState<boolean>(false);
  const location = useLocation();
  const [userRole, setUserRole] = useState(GetUserRole());
  const [pORId, setPORId] = useState<any>();
  const [referenceTypeList, setReferenceTypeList] = useState<any>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [referenceRecords, setReferenceRecords] = useState<{Id: string | null;referenceTypeId: string;itemSelected: boolean;referencesValue: string;referenceTypename: string;}[]>([]);
  const [toastTitle, setToastTitle] = useState<string>("");
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState<CoherenceNotificationType>( CoherenceNotificationType.SUCCESS);
  const [showToast, setShowToast] = React.useState<boolean>(false);
  const [showAddGridPanel, setShowAddGridPanel] = useState<boolean>(false);
  const [selectedReferenceType, setSelectedReferenceType] =  React.useState<IDropdownOption>();
  const [onSaveDataValid, setOnSaveDataValid] = React.useState<boolean>(false);
  const [selectedOtherRefType, setOtherSelectedRefType] = useState<string>("");
  const [selectedreferencesValue, setSelectedreferencesValue] = useState<string>("");
  const [showEditGridPanel, setShowEditGridPanel] = useState<boolean>(false);
  const [editColumnsDefaultValues, seteditColumnsDefaultValues] =useState<any>();
  const [showOnSave, setShowOnSave] = React.useState<boolean>(false); 
  const [bulkEditSelectedData, setBulkEditSelectedData] = useState<any>([]);
  const [isSelectedAll, setIsSelectedAll] = React.useState<boolean>(false);
  const [selectedNGVLReferenceType, setSelectedNGVLReferenceType] =React.useState<IDropdownOption>();
  const [showDropDown, setShowDropDown] = React.useState<boolean>(false);
  const [referenceValueDropdown, setReferenceValueDropdown] = useState<any>([]);
  const [isRefTypeInActive, setIsRefTypeInActive] =useState<any>();
  useEffect(() => {
   const routerName = location.pathname.split("/")[1];
    if (routerName === "CreatePOR") {
      setIsEditMode(false);
    } else if (routerName === "PORDetails") {
      const idStartIndex = window.location.href.lastIndexOf('/');
      const id = idStartIndex !== -1 ? window.location.href.substring(idStartIndex + 1) : null;  
      if (id) {
        LoadReferencesData(id);
        setIsEditMode(true);
        setPORId(parseInt(id));
       // generateReferenceList();
      }
    }
  }, [location]);

  useEffect(() => {
    const routerName = location.pathname.split('/')[1];
    if (routerName === "CreatePOR" && props.Reset) {
      setReferenceRecords([]);
    }
  }, [props.Reset]);


 useEffect(() => {
    if (props.referenceDetailList.length > 0) {
     setReferenceTypeList(props.referenceDetailList);
   }
   }, [props.referenceDetailList]);

   const validateUrl = (value: string)  => {
    if (!value || /^(ftp|http|https):\/\/[^ "]+$/.test(value)) {
      return true; // Valid URL
    } else {
      return false; // Invalid URL
    }
  }
 const generateReferenceList = () => {
         let refTypeListTemp = referenceTypeList.sort((a: any, b: any) => a.text.localeCompare(b.text));
         setReferenceTypeList(refTypeListTemp);
       }

//        if (!isEditMode) {
//           props.onSaveReferenceDetails(referenceRecords);
// }

  // API call to get reference type list

const LoadReferencesData = (PORID: string) => {
      const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      mode: "cors",
    };
    setIsLoaded(true);
    DOMEApiHelper("PORDetails/GetReferenceValuesById?id=" + PORID,requestOptions)
      .then((res) => {
        setIsLoaded(false);    
        setReferenceRecords([
          ...res.map((item: any) => ({
            refId: item.Id,
            referenceTypeId: item.ReferenceType,
            referencesValue: item.ReferenceValue,
            referenceTypename: item.ReferenceTypeName,
            itemSelected: false
          })),
        ]);
      })
      .catch((res) => {
        setIsLoaded(false);
      });
  };

  // API call to update reference data

  const updateReferenceData = (editedData: any,reqFrom:any) => {
    const requestUpdateOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(editedData),
      mode: "cors",
    };
         setToastTitle(reqFrom==="Add"?"Add Reference":"Update Reference");
         setToastMessage(reqFrom==="Add"?"Adding Reference is in progress":"Updating Reference is in progress");
         setToastType(CoherenceNotificationType.PENDING);
         setShowToast(true);
         DOMEApiHelper("PORDetails/UpdateReferenceDetails", requestUpdateOptions)
            .then((res) => {
             setToastType(CoherenceNotificationType.SUCCESS); 
             setToastMessage(reqFrom==="Add"?"Reference has been added successfully.":"Reference has been updated successfully.");
             setTimeout(() => {
             setShowToast(false);
           }, 3000);

         if (res.Message === "Success") {
             LoadReferencesData(pORId);
         }
        })
          .catch((res) => {
           setToastMessage(res.toString());
           setToastType(CoherenceNotificationType.ERROR);
           setTimeout(() => {
           setShowToast(false);
        }, 3000);
      });
  };

  const generateReferenceButton = (): any => {
    const _items: ICommandBarItemProps[] = [
      {
        key: "Add",
        text: "Add",
        iconProps: { iconName: "Add" },
        onClick: (item) => {
          setIsRefTypeInActive(false);
          setShowDropDown(false);
          setSelectedReferenceType(undefined);
          generateReferenceList();
          setShowAddGridPanel(true);
          setIsEditItem(false); 
        } 
      },
      {
        key: "ActionsDelete",
        name: "Delete",
        type: "actions",
        iconProps: {
          iconName: "Delete",
          style: { color: "red" },
        },
        isResizable: false,
        menuActions: [],
        minColumnWidth: 55,
        maxWidth: 55,
        data: [],
        onClick: () => {
          const result = window.confirm(
            "Are you sure you want to delete the selected Reference(s)?"
          );
          if (result) {
            // Edit POR Delete
            if (pORId > 0 && isEditMode) {
              let todeleterecords = bulkEditSelectedData
                .filter((item: any) => item.itemSelected === true)
                .map((item: any) => item.refId);
              const requestUpdateOptions = {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(todeleterecords),
                mode: "cors",
              };
              setToastTitle("Delete Reference(s)");
              setToastMessage("Deleting the selected Reference(s)");
              setToastType(CoherenceNotificationType.PENDING);
              setShowToast(true);
              DOMEApiHelper("PORDetails/DeleteReferenceDetails",requestUpdateOptions)
                .then((res) => {
                  setIsSelectedAll(false);
                  setToastType(CoherenceNotificationType.SUCCESS); 
                  setToastMessage("Reference(s) have been successfully deleted.");
                  setTimeout(() => {
                    setShowToast(false);
                  }, 3000);

                  if (res.Message === "Success") {
                    LoadReferencesData(pORId);

                    //pushing the mutilpe deleted records to the ReferenceTypeList
                     bulkEditSelectedData
                    .filter((item: any) => item.itemSelected === true)
                    .map((item: any) => ({
                      key: parseInt(item.referenceTypeId),
                      text: item.referenceTypename,
                    }))
                    .forEach((x: any) => {
                      if (!referenceTypeList.some((item: any) => item.key === x.key)) {
                        referenceTypeList.push(x);
                      }
                    });
                      setIsSelectedAll(false);      
                    }
                    
                  setBulkEditSelectedData([]);
                })
                .catch((res) => {
                  setToastMessage(res.toString());
                  setToastType(CoherenceNotificationType.ERROR);
                  setTimeout(() => {
                    setShowToast(false);
                  }, 3000);
                });
            } else {
              // Create POR Delete

              let notremoveDeletedReferences = referenceRecords.filter(
                (item: any) => item.itemSelected === false
              );
              setReferenceRecords(notremoveDeletedReferences);

             // pushing the mutilpe deleted records to the ReferenceTypeList
              bulkEditSelectedData
                .filter((item: any) => item.itemSelected === true)
                .map((item: any) => ({
                  key: parseInt(item.referenceTypeId),
                  text: item.referenceTypename,
                }))
                .forEach((x: any) => {
                  if (!referenceTypeList.some((item: any) => item.key === x.key)) {
                    referenceTypeList.push(x);
                  }
                });

               

               setIsSelectedAll(false);
               setBulkEditSelectedData([]);
            }
          } // Alert box
        },
        disabled: (bulkEditSelectedData.length > 0) ? false : true,
      },
    ];
    return _items;
  };


  const generateReferenceValueDropdown = (referenceValueDropdown:String,editObject?:any) => {
               const requestOptions = {
                    method: "GET",
                    headers: { "Content-Type": "application/json" },
                    mode: "cors",
                }; 
                DOMEApiHelper("PORDetails/GetReferenceValuebyType?referenceType=" + referenceValueDropdown,requestOptions)
                  .then((res) => { 
                    let refValues=[
                      ...res.map((item: any) => ({
                      key: item.Id,
                      text: item.Title,
                    }))
                  ]
                  setReferenceValueDropdown(refValues);

                 if(!isEmpty(editObject)){
                  let referenceValueDropdownKey = refValues.filter((x: any) => x.text === editObject.referencesValue).map((item: any) => item);
                  setSelectedNGVLReferenceType({key:parseInt(referenceValueDropdownKey[0].key), text:editObject.referencesValue});                                
                 }

               })
              .catch((res) => {
                
         });
   }

  const handleSelectAllCheckbox = (event: any) => {
        setIsSelectedAll(event.target.checked);
        referenceRecords.forEach((x: any) => {
        x.itemSelected = event.target.checked;
    });
    setReferenceRecords([...referenceRecords]);
       const updateBulkUpdateData = event.target.checked? referenceRecords.filter((x: any) => x.itemSelected === true) : [];
        setBulkEditSelectedData([...updateBulkUpdateData]);
   };

  const handleSelectionCheckbox = (event: any, item: any) => {
     if (event.target.checked) {
         item.itemSelected = true;
         setBulkEditSelectedData([...bulkEditSelectedData, item]);
     } else {
         if (isSelectedAll) setIsSelectedAll(false);
            item.itemSelected = false;
            setBulkEditSelectedData(
            bulkEditSelectedData.filter(
            (selectedItem: any) => selectedItem.referenceTypeId.toString() !== item.referenceTypeId.toString() && selectedItem.referencesValue !== item.referencesValue
         )
       );
    }

    referenceRecords.forEach((element: any) => {
        if (element.referenceTypeId === item.referenceTypeId && element.referencesValue === item.referencesValue) {
          element.itemSelected = item.itemSelected;
        }
    });
    setReferenceRecords([...referenceRecords]);
   };

  const onchangeRefType = ( event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption,index?: number): void => {
     setSelectedReferenceType(item);  
    if(item?.text === "NGVL Release Type"){
           generateReferenceValueDropdown(item?.text);
           setShowDropDown(true);
      }
      else{
            setShowDropDown(false);
          }
    };
  
  const onchangeEditNGVLReferenceType = (
            event: React.FormEvent<HTMLDivElement>,
            item?: IDropdownOption,
            index?: number
    
       ): void => {
           setSelectedNGVLReferenceType(item); 
           setSelectedreferencesValue(item?.text ?? "");
    };

  const onchangeNGVLReferenceType = (
            event: React.FormEvent<HTMLDivElement>,
            item?: IDropdownOption,
            index?: number
        
     ): void => {
            setSelectedNGVLReferenceType(item);
            setSelectedreferencesValue(item?.text ?? "");   
    };

  const onChangeTextFieldValue = (
         event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
         newValue?: string
         ) => {
          setOtherSelectedRefType(newValue || "");
    };

  const onChangeReferenceValue = (
         event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
         newValue?: string
         ) => {
         setSelectedreferencesValue(newValue || "");
    };
  
  const onChangeEditReferenceValue = (
         event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
         newValue?: string
         ) => {
        seteditColumnsDefaultValues({...editColumnsDefaultValues, referencesValue:newValue || ""});
    };

      const generateAddControls = () => {
            let tmpRenderObj: any[] = [];
             tmpRenderObj.push(
               <>
                 <Dropdown
                   label={"Reference Type"}
                   placeholder={"Please select Reference Type"}
                   required={true}
                   options={referenceTypeList}
                   selectedKey={
                      selectedReferenceType ? selectedReferenceType.key : undefined
                   }
                   errorMessage={
                       onSaveDataValid && selectedReferenceType === undefined
                       ? "Please select Reference Type"
                       : undefined
                   }
         
                   onChange={(
                     event: React.FormEvent<HTMLDivElement>,
                     option?: IDropdownOption<any> | undefined,
                     index?: number | undefined
                   ) => {
                      onchangeRefType(event, option, index);
                   }}
                 />
               </>
             );
             tmpRenderObj.push(
               showDropDown ? (
                 <>
                   <Dropdown
                     label={"Reference Value"}
                     options={referenceValueDropdown}
                     placeholder={"Please select NGVL Release Type"}
                     selectedKey={
                       selectedNGVLReferenceType ? selectedNGVLReferenceType.key : undefined
                     }
         
                     onChange={(
                       event: React.FormEvent<HTMLDivElement>,
                       option?: IDropdownOption<any> | undefined,
                       index?: number | undefined
                     ) => {
                       onchangeNGVLReferenceType(event, option, index);
                     }}
                   />
                 </>
               ) : (
                 <TextField
                   label={"Reference Value"}
                   placeholder=" Enter Reference Value"
                   onChange={onChangeReferenceValue}
                   required={true}
                   errorMessage={
                     onSaveDataValid && isEmpty(selectedreferencesValue)
                       ? "Please enter Reference Value"
                       : undefined
                   }
                 />
               )
             );
         
             tmpRenderObj.push(
               <div>
                 <br></br>
                 <PrimaryButton disabled={disableSaveButton()} onClick={addReference}>Add Reference</PrimaryButton>
               </div>
           );
        return tmpRenderObj;
   };

  const addReference = () => {
        setOnSaveDataValid(true);
        if (!isDataValid()) {
            return false;
        }
        const newReferenceRecord = selectedReferenceType? {
                  Id: null,
                  referenceTypeId: selectedReferenceType.key.toString(),
                  itemSelected: false,
                  referenceTypename: selectedReferenceType.text,
                  referencesValue: selectedreferencesValue,
           }: null;
        
        if (newReferenceRecord) {
              setReferenceRecords([...referenceRecords, newReferenceRecord]);
          }
        if (isEditMode && pORId > 0) {
          let editedData = {
               PORId: parseInt(pORId),
               ReferenceType: newReferenceRecord? newReferenceRecord.referenceTypeId: null,
               ReferenceValue: newReferenceRecord? newReferenceRecord.referencesValue: null,
               CreatedBy: sessionStorage.getItem("localAccountId"),
             };
           updateReferenceData(editedData,"Add"); // Api Call
        }else{
          if (newReferenceRecord) { 
             props.onSaveReferenceDetails([...referenceRecords, newReferenceRecord]);
          }
         }
          setShowAddGridPanel(false);
          setOnSaveDataValid(false);   
          setShowOnSave(false);
          setSelectedReferenceType(undefined);
          setSelectedreferencesValue("");
          setSelectedNGVLReferenceType(undefined); 
          setShowDropDown(false);
          seteditColumnsDefaultValues(undefined);
          setOtherSelectedRefType("");
          setBulkEditSelectedData([]);
    
    };

  const isDataValid = () => {
              // for Adding a Reference
        if (!isEditItem &&(isEmpty(selectedreferencesValue) || isEmpty(selectedReferenceType))) 
              {
                return false;
              }
              // edit Reference
            
        if (isEditItem && isEmpty(selectedreferencesValue)) 
              {
              return false;
              }
              return true;
   };

  const getGridColumns = (): CoherenceDataGridColumn<any>[] => {
           var advancedColumns: CoherenceDataGridColumn<any>[] = [
                {
                  key: "selectAll",
                  name: "Select All",
                  fieldName: "SelectAll",
                  type: "string",
                  isResizable: false,
                  minColumnWidth: 20,
                  maxWidth: 20,
                  ariaLabel: "Select All",
                  data: [],
                  onRender: (item: any) => {
                    return (
                      <Checkbox
                        ariaLabel="Select row"
                        onChange={(event) => handleSelectionCheckbox(event, item)}
                        checked={item.itemSelected}
                      />
                    );
                  },
                  onRenderHeader: (item: any) => {
                    return (
                      <Checkbox
                        ariaLabel="Select all rows"
                        styles={{ root: { marginTop: "12px", marginBottom: "4px" } }}
                        onChange={(event) => handleSelectAllCheckbox(event)}
                        checked={isSelectedAll}
                      />
                    );
                  },
                },
                {
                  key: "Actions",
                  name: "Edit",
                  type: "actions",
                  isResizable: false,
                  menuActions: [],
                  minColumnWidth: 40,
                  maxWidth: 40,
                  data: [],
                  visibleActions: [
                    {
                      onClick: (item) => { 
                        let isRefActive=referenceTypeList.filter((x: any) => x.key === parseInt(item.referenceTypeId));
                        if(isRefActive.length==0){
                          setIsRefTypeInActive(true);  
                        }
                        else{
                          setIsRefTypeInActive(false);  
                        }
                        seteditColumnsDefaultValues(item);
                        setSelectedreferencesValue(item.referencesValue);
                        setSelectedReferenceType({key:parseInt(item.referenceTypeId), text:item.referenceTypename});
                        if(item.referenceTypename === "NGVL Release Type" ){
                          setShowDropDown(true);    
                          if(isEmpty(referenceValueDropdown)){
                            generateReferenceValueDropdown(item.referenceTypename,item);
                          }
                          else{
                            let referenceValueDropdownKey = referenceValueDropdown.filter((x: any) => x.text === item.referencesValue).map((item: any) => item);
                            setSelectedNGVLReferenceType({key:parseInt(referenceValueDropdownKey[0].key), text:item.referencesValue});      
                          }
                           }
                          else{
                              setShowDropDown(false);
                          }
          
                        setIsEditItem(true); 
                        setShowEditGridPanel(true);
                      },
                      key: "edit",
                      text: "Action",
                      ariaLabel: "Edit",
                      iconProps: {
                        iconName: "Edit",
                        style: { color: "#0078d4" },
                      },
                    },
                  ],
                },
                {
                  key: "ReferenceType",
                  name: "Reference Type",
                  fieldName: "ReferenceType",
                  type: "string",
                  isResizable: true,
                  ariaLabel: "ReferenceType",
                  minColumnWidth: 150,
                  maxWidth: 150,
                  data: [],
                  onRender: (item) => {
                    return (
                      <span style={{ fontSize: 14, color: "rgb(50, 49, 48)" }}>
                        {item.referenceTypename}
                      </span>
                    );
                  },
                },
                {
                  key: "ReferenceValue",
                  name: "Reference Value",
                  fieldName: "ReferenceValue",
                  type: "string",
                  isResizable: true,
                  ariaLabel: "Reference Value ",
                  minColumnWidth: 180,
                  maxWidth: 180,
                  data: [],
                  onRender: (item) => {
                    if (validateUrl(item.referencesValue)) {
                      return (
                        <a href={item.referencesValue} target="_blank" rel="noopener noreferrer">
                          {item.referencesValue}
                        </a>
                      );
                    } else {
                      return (
                        <span style={{ fontSize: 14, color: "rgb(50, 49, 48)" }}>
                          {item.referencesValue}
                        </span>
                      );
                    }
                  }
                },
                
              ];   
return advancedColumns;
  }
                        const handleOnPanelXClose = () => {
                   setShowAddGridPanel(false);
                   setShowEditGridPanel(false);
   };

  const handleEdit = () => {
    if (selectedreferencesValue === editColumnsDefaultValues.referencesValue) { 
      return false;
    }
    else {
      if (isEditMode) {
        setOnSaveDataValid(true);

          if (!isDataValid()) {
               return false;
          }
          
            let editedData = {
                 PORId: parseInt(pORId),
                 Id: editColumnsDefaultValues.refId,
                 ReferenceType: editColumnsDefaultValues.referenceTypeId,
                 ReferenceValue:selectedreferencesValue,
                 ModifiedBy: sessionStorage.getItem("localAccountId"),
          };
        
         updateReferenceData(editedData,"Update"); 
         setShowEditGridPanel(false);

        
       }
      else {
            referenceRecords.forEach((x: any) => {
                if (x.referenceTypeId === editColumnsDefaultValues.referenceTypeId) {
                  x.referencesValue=selectedreferencesValue;
                 }
               })
               setReferenceRecords([...referenceRecords]);
               setShowEditGridPanel(false);
      
           }
          
          }
          setShowAddGridPanel(false);
          setOnSaveDataValid(false); 
          setShowOnSave(false);
          setSelectedReferenceType(undefined);
          setSelectedreferencesValue("");
          setSelectedNGVLReferenceType(undefined); 
          setShowDropDown(false);
          seteditColumnsDefaultValues(undefined);
          setOtherSelectedRefType("");
          setBulkEditSelectedData([]);
    
  };

      const [editRowColumns, seteditRowColumns] = useState<any>([
            {
              isEditable: true,
              type: "ReadOnly",
              label: "Reference Type",
              key: "referenceTypename",
            },
            {
              isEditable: true,
              type: "Reference",
              label: "Reference Value",
              key: "referencesValue",
            },
      ]);

      const disableSaveButton = (): boolean => { 
        let isInValid=false;
        if(isEmpty(selectedReferenceType) || isEmpty(selectedreferencesValue.replace(/<\/?[^>]+(>|$)/g, '').replace(/&nbsp;/g, '').trim())){
          isInValid=true;
        }  
        else if(referenceRecords.some((item: any) => item.referenceTypeId.toString() === selectedReferenceType?.key.toString() && item.referencesValue == selectedreferencesValue.replace(/<\/?[^>]+(>|$)/g, '').replace(/&nbsp;/g, '').trim())){
         isInValid=true; 
        } 
        return isInValid;
    }  
    
    const generateEditControls = (): any => {
      let tmpRenderObj: any[] = [];
      for (let editRowColumn of editRowColumns) {
     
        switch (editRowColumn.type) {
        case "ReadOnly":
          tmpRenderObj.push(
            <><Label key={editColumnsDefaultValues[editRowColumn.key]}>{editRowColumn.label}</Label> <Label className="readonyLabel" disabled>{editColumnsDefaultValues[editRowColumn.key]}</Label></>                      
          ); 
          break;
        case "Reference":
        // based on showdropdown need to show dropdown or textfield
        tmpRenderObj.push(
          showDropDown ? (
            <>
              <br></br>
              <Dropdown
                options={referenceValueDropdown}
                placeholder={"Please select NGVL Release Type"}
                selectedKey={
                 selectedNGVLReferenceType ? selectedNGVLReferenceType.key : undefined
                }
    
                onChange={(
                  event: React.FormEvent<HTMLDivElement>,
                  option?: IDropdownOption<any> | undefined,
                  index?: number | undefined
                ) => {
                  onchangeEditNGVLReferenceType(event, option, index);
                }}
              />
            </>
          ) : (
            <TextField
              label={"Reference Value"}
              placeholder=" Enter Reference Value"
              value={selectedreferencesValue}
              onChange={onChangeReferenceValue}
              required={true}
              errorMessage={
                onSaveDataValid && isEmpty(editColumnsDefaultValues.referencesValue)
                  ? "Please enter Reference Value"
                  : undefined
              }
            />
          )
        );    
          break;
        default:
      }
    }
    tmpRenderObj.push(
      <>
        <br></br>
        <PrimaryButton  disabled={disableSaveButton() || isRefTypeInActive} onClick={handleEdit}>Save Reference</PrimaryButton>
      </>
    );

    return tmpRenderObj;
  };

  return (
    <div>
      {showToast && (
        <ToastMessage
          showToast={showToast}
          type={toastType}
          message={toastMessage}
          title={toastTitle}
        ></ToastMessage>
      )}
      <div style={{ display: "flex", justifyContent: "end" }}>
        <CommandBar
          items={[]}
          farItems={generateReferenceButton()}
          ariaLabel="Add Reference"
          primaryGroupAriaLabel="Add Reference"
          farItemsGroupAriaLabel="Add Reference"
          style={{ display: "flex", justifyContent: "start" }}
        />
      </div>
      <div
        style={{
          minHeight: 255,
          height: 255,
          maxHeight: 255,
          overflowX: "auto",
        }}
      >
        {!isLoaded ? (
          <CoherenceDataGrid
            listConfig={getGridColumns()}
            data={referenceRecords}
            ariaLabelForSelectAllCheckbox={"select all checkbox"}
            isScrollable={true}
            isSortable={false}
            sortByDefault={false}
            layoutMode={DetailsListLayoutMode.fixedColumns}
            ariaLabel={"Reference List"}
          ></CoherenceDataGrid>
        ) : (
          <CoherenceLoading primaryText={""}></CoherenceLoading>
        )}
      </div>
      <CoherencePanel
        title={"Reference"}
        titleText={"Reference"}
        isOpen={showAddGridPanel}
        onDismiss={handleOnPanelXClose}
        panelSize={CoherencePanelSize.small}
        closeButtonAriaLabel="Close"
      >
        {props.ServiceLineError ? <><svg width="16" height="20" viewBox="0 -9 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12.0001 1.99805C17.5238 1.99805 22.0016 6.47589 22.0016 11.9996C22.0016 17.5233 17.5238 22.0011 12.0001 22.0011C6.47638 22.0011 1.99854 17.5233 1.99854 11.9996C1.99854 6.47589 6.47638 1.99805 12.0001 1.99805ZM11.9963 10.2486C11.4834 10.249 11.061 10.6353 11.0036 11.1327L10.9969 11.2493L11.0005 16.7509L11.0073 16.8675C11.0654 17.3648 11.4884 17.7506 12.0012 17.7502C12.514 17.7499 12.9364 17.3636 12.9939 16.8662L13.0005 16.7496L12.9969 11.248L12.9901 11.1314C12.932 10.6341 12.5091 10.2483 11.9963 10.2486ZM12.0005 6.49908C11.3093 6.49908 10.749 7.0594 10.749 7.7506C10.749 8.4418 11.3093 9.00212 12.0005 9.00212C12.6917 9.00212 13.2521 8.4418 13.2521 7.7506C13.2521 7.0594 12.6917 6.49908 12.0005 6.49908Z" fill="#212121" />
</svg> <span style={{color:"#a4262c"}}>A service line selection is required to proceed.</span> </> : null }
        {showAddGridPanel && generateAddControls()}
      </CoherencePanel>
      <CoherencePanel
        title={"Edit Reference"}
        titleText={"Edit Reference"}
        isOpen={showEditGridPanel}
        onDismiss={handleOnPanelXClose}
        panelSize={CoherencePanelSize.small}
        closeButtonAriaLabel="Close"
      >
        {showEditGridPanel && generateEditControls()}
      </CoherencePanel>
    </div>
  );
};
export default References;
