import React, { useEffect, useState } from 'react';
import Accordion from '../../../shared/Accordion/Accordion';
import { CoherenceNotificationType, CoherenceLoading } from '@coherence-design-system/controls';
import { mergeStyleSets, IActivityItemProps, Stack, ActivityItem, IStackStyles, IStackProps } from '@fluentui/react';
import { Link } from 'react-router-dom';
import { CustomRTEPlugin } from '../../../shared/CustomRTEPlugin/CustomRTEPlugin';
import { DOMEApiHelper, isEmpty, calculateTimeAgo } from '../../../utils/DOMEApiHelper';
import type { EditorPlugin } from 'roosterjs';
import { RichTextEditor } from "@coherence-design-system/rich-text-editor";
import { Card } from "@coherence-design-system/card";
interface Props {
    discussionId: string;
    discussionType: string;
    onValidDiscussionData: any;
    isExpand: boolean;
    isBulkSelected?: boolean;
}

const NonManagedDiscussions: React.FC<Props> = ({ discussionId, discussionType, onValidDiscussionData,isExpand,isBulkSelected}) => {

    const [toastType, setToastType] = useState<CoherenceNotificationType>(CoherenceNotificationType.SUCCESS);
    //discussion code start
    const [showDiscussionControl, setShowDiscussionControl] = useState<boolean>(true);
   const [discussionRecords, setDiscussionRecords] = useState<any[]>([]);
    const [selectedDiscussion, setSelectedDiscussion] = useState<string>("");
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastMessage, setToastMessage] = useState<string>("");
    const [toastTitle, setToastTitle] = useState<string>("");

    const stackTokens = { childrenGap: 50 };
    const stackStyles: Partial<IStackStyles> = { root: { whiteSpace: 'nowrap' } };
    const columnProps: Partial<IStackProps> = {
        tokens: { childrenGap: 20 },
        styles: { root: { width: '100%' } },
    };


    const LoadDiscussionData = (discussionId: string) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            mode: 'cors'
        };
       
        DOMEApiHelper('PORDetails/GetDiscussionDetails?DiscussionId=' + discussionId + '&DiscussionType=' + discussionType, requestOptions)
            .then(res => {
               
                let result = [
                    ...res.map((item: any) => ({
                        Id: item.Id,
                        Discussion: item.Discussion,
                        CreatedByUserName: item.CreatedByUserName,
                        CreatedBy: item.CreatedBy,
                        CreatedDate: item.CreatedDate
                    }))
                ]
                //sort result by created date descending order
                result = result.sort((a, b) => new Date(b.CreatedDate).getTime() - new Date(a.CreatedDate).getTime());
                setDiscussionRecords(result);

            }).catch(res => {
                setIsLoaded(false);
            });
    }

    const classNames = mergeStyleSets({
        commentsText: {
            marginTop: '10px',
        },
        nameText: {
            fontWeight: 'bold',
            color:'rgb(0, 90, 158)'
        },

    });

   
    // for to remove html tags while displaying in Comments
    const renderHTML = (escapedHTML: string) =>
        React.createElement("div", { dangerouslySetInnerHTML: { __html: escapedHTML } });

    const activityItemExamples: (IActivityItemProps & { key: string | number })[] = [];
    discussionRecords.forEach((item: any) => {
        activityItemExamples.push(
            {
                key: item.Id,
                activityDescription: [<Link key={1} className={classNames.nameText} to={''}> {item.CreatedByUserName} </Link>,
                <span key={2}> {"commented"} </span>,],
                activityPersonas: [{ imageUrl: item.CreatedByUserName }],
                //activityPersonas:[{ imageInitials: item.CreatedByUserName , text: item.CreatedByUserName }],
                comments: renderHTML(item.Discussion),
                timeStamp: calculateTimeAgo(item.CreatedDate)
            });
    });

    const handleRichTextChange = (value: string | undefined): void => {
        if(!isEmpty(value)){  
            // Create a new DOMParser
            const parser = new DOMParser(); 
            // Parse the HTML string
            const doc = parser.parseFromString(value || "", 'text/html'); 
            // Get the text content from the parsed document
            const extractedText = doc.body.textContent;  
        if(!isEmpty(extractedText)){  
            setSelectedDiscussion(value || "");
            validDiscussionData(value);     
            }
        } else{
                setSelectedDiscussion(value || "");
                validDiscussionData(value);     
         }
    }



    const validDiscussionData = async (value: any) => {

        let discussionRecord = {
            DiscussionId: discussionId,
            DiscussionType: discussionType,
            Discussion: value,
            CreatedBy: sessionStorage.getItem("localAccountId")
        }

        onValidDiscussionData(discussionRecord, value);


    }


    useEffect(() => {
        if(!isEmpty(discussionId) && !isBulkSelected){      
                 LoadDiscussionData(discussionId);
        }
    }, [discussionId]); 

    const generateDiscussionControls = (): any => {
        let tmpRenderObj: any[] = [];
        if (showDiscussionControl) {
            tmpRenderObj.push(
                <div
                    style={{
                        height: '180px',
                        marginTop: '-10px'
                    }}
                >
                    <RichTextEditor
                        characterMax={4000}
                        label={""}
                        value={selectedDiscussion}
                        onBlur={() => { }}
                        onChange={handleRichTextChange}
                        onFocus={() => { }}
                        onInvalid={() => { }}
                        onValid={() => { }}
                        placeholder="Add your comments"
                        resizable={true}
                        plugins={getPlugins()}
                    />
                </div>
            );
        }
        return tmpRenderObj;
    }
    //plugin code start 
    const getPlugins = () => {
        let plugins: EditorPlugin[] = [];
        plugins.push(
            new CustomRTEPlugin((content: any) => {
            })
        );
        return plugins;
    };
    //plugin code end 

    return (
        <div style={{paddingTop: 10}}>
            <Accordion title="Discussions" isExpandDefault={isExpand} >
                <div style={{ maxHeight: 450 }}>
                    <Card >
                        {!isLoaded ? <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                            <Stack {...columnProps}>
                                {generateDiscussionControls()}
                            </Stack>
                        </Stack>
                            : <div style={{ minHeight: 300, height: 300, maxHeight: 300 }}>
                                <CoherenceLoading primaryText={""}></CoherenceLoading>
                            </div>}

                        <div>
                            {!isLoaded ?
                                <div style={{ overflowY: 'auto', maxHeight: '230px', display: 'float', scrollBehavior: 'smooth' }}>
                                    {activityItemExamples.map((item: any) =>

                                        (<ActivityItem {...item} className={classNames.commentsText} />))}
                                </div>
                                : ""
                            }
                        </div>

                    </Card>
                </div>
            </Accordion>
        </div>
    );
};

export default NonManagedDiscussions;


