import { IStackStyles, IStackProps, DetailsListLayoutMode, CommandBar, ICommandBarItemProps, Checkbox, Link, PrimaryButton, Label, Dropdown, IDropdownOption, TextField, DatePicker, IconButton } from "@fluentui/react";
import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router";
import ToastMessage from "../../../../../shared/ToastMessage/ToastMessage";
import PORDetails from "../PORDetails";
import { CoherenceDataGrid, CoherenceDataGridColumn, CoherenceLoading, CoherenceNotificationType, CoherencePanel, CoherencePanelSize } from "@coherence-design-system/controls";
import { GetUserRole } from "../../../../../utils/GetUserRole";
import { DOMEApiHelper, isEmpty, onFormatDate } from "../../../../../utils/DOMEApiHelper";
import dayjs from "dayjs";
import { NonMangedStyles } from "../../../../NonManaged/NonManaged.styles";

interface prop {
  onSaveMilestone: any;
  MilestonesTypeList:any;
  ServiceLineError:boolean;
  Reset: boolean;
}
export const Milestones = (props: prop) => { 

  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastTitle, setToastTitle] = useState<string>("");
  const [showToast, setShowToast] = React.useState<boolean>(false);
  const [toastType, setToastType] = useState<CoherenceNotificationType>(CoherenceNotificationType.SUCCESS);
  const [isEditMode, setIsEditMode] = useState(false);
  const location = useLocation(); 
  const [userRole, setUserRole] = useState(GetUserRole());
  const [showMilestonePanel, setShowMilestonePanel] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState(false); 
  const [isSelectedAll, setIsSelectedAll] = React.useState<boolean>(false);
  const [bulkEditSelectedData, setBulkEditSelectedData] = useState<any>([]);
  const [pORId,setPORId]= useState<any>();   
  const [milestoneRecords,setMilestoneRecords]= useState<{ MilestoneTypeName:string; MilestoneDate: string; MilestoneType : string; itemSelected : boolean}[]>([]);
  const [onSaveMilestone, setOnSaveMilestone] = React.useState<boolean>(false); 
  const [selectedItem, setSelectedItem] = React.useState<IDropdownOption>();
  const [selectedMilestone, setSelectedMilestoneType] = useState<any>(''); 
  const [selectedMilestoneDate,setMilestoneDate] = useState<any>(dayjs(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSS"));
  const [isEditMilestoneClicked, setIsEditMilestoneClicked] = React.useState<boolean>(false); 
  const [originalSelectedMilestoneDate, setOriginalSelectedMilestoneDate] = useState<any>(''); 
  const [editColumnsDefaultValues, seteditColumnsDefaultValues] = useState<any>();
  const [isMilestoneTypeInActive, setIsMilestoneTypeInActive] =useState<any>(false); 
  useEffect(() => {  
    const routerName = location.pathname.split('/')[1];
    if (routerName==='CreatePOR') {
      setIsEditMode(false);
    } else if(routerName==='PORDetails'){
      setIsEditMode(true);
    } 
  }, [location]);

  useEffect(() => {
    const routerName = location.pathname.split('/')[1];
    // remove all value on service line change from create Por
    if(routerName==='CreatePOR' && props.Reset){
      setMilestoneRecords([]);
    }
  }, [props.Reset]);
 
  const handleOnPanelXClose = () => {
    setShowMilestonePanel(false); 
    setOriginalSelectedMilestoneDate(undefined);
  }

  // Single Selection Box
  const handleSelectionCheckbox = (event:any,item:any) => {
    if (event.target.checked) {
      item.itemSelected =true;
      setBulkEditSelectedData([...bulkEditSelectedData, item]);
      
      } else {
        if (isSelectedAll)
          setIsSelectedAll(false);
        item.itemSelected =false;
        setBulkEditSelectedData(
        bulkEditSelectedData.filter((selectedItem: any) => selectedItem.MilestoneTypeName !== item.MilestoneTypeName)
        );
        
      }   
      
      milestoneRecords.forEach((element:any) => {
          if(element.MilestoneType===item.MilestoneType){
              element.itemSelected=item.itemSelected
          }
      });  
      setMilestoneRecords([...milestoneRecords]);  
  }

  

  // multiple Selection Box
  const handleSelectAllCheckbox = (event:any)=> {
    setIsSelectedAll(event.target.checked); 
            milestoneRecords.forEach((x:any)=>{
              if (x.MilestoneTypeName !== 'Target Completion Date'){
                x.itemSelected = event.target.checked;
                }
             
              })
                setMilestoneRecords([...milestoneRecords]);
                const updateBulkDeleteData = event.target.checked ? milestoneRecords.filter((x: any) => x.itemSelected === true) : [];
                setBulkEditSelectedData([...updateBulkDeleteData]) 
  }

  const handleDateSelection = (date: any) => {
    setMilestoneDate(dayjs(date).format("YYYY-MM-DDTHH:mm:ss.SSS")); 
  }

  const handleMilestoneSave = () => {   

    if( pORId>0){      
      let updatemilestonedata = {
          PORId:parseInt(pORId), 
          Id: isEditMilestoneClicked? editColumnsDefaultValues.Id:null,
          MilestoneType: selectedMilestone.key,
          MilestoneDate: selectedMilestoneDate,
          CreatedBy: sessionStorage.getItem("localAccountId"),
          ModifiedBy: isEditMilestoneClicked? sessionStorage.getItem("localAccountId"):null
      } 
       
      setToastTitle(!isEditMilestoneClicked?"Add Milestone":"Update Milestone");
      setToastMessage(!isEditMilestoneClicked?"Adding Milestone is in progress":"Updating Milestone is in progress");
      setToastType(CoherenceNotificationType.PENDING);
      setShowToast(true); 
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatemilestonedata),
        mode: 'cors'
    };
    
    DOMEApiHelper('PORDetails/SaveMilestone', requestOptions)
                .then(res => {
                    setToastType(CoherenceNotificationType.SUCCESS);
                    setToastMessage(!isEditMilestoneClicked?"Milestone has been added successfully.":"Milestone has been updated successfully."); 
                    setTimeout(() => {
                        setShowToast(false);
                    }, 3000);

                    if (res.Message==="Success") {
                      LoadMilestoneData(pORId) 
                    }
                }).catch(error => {
                    setToastType(CoherenceNotificationType.ERROR);
                    setToastMessage(error.toString());
                    setTimeout(() => {
                        setShowToast(false);
                    }, 3000);
                }
            );
    }
    else{
      if(!isEditMilestoneClicked){
        // Uploaded part      
        let newMilestone = {
          MilestoneTypeName: props.MilestonesTypeList.filter((milestone:any)=>milestone.key==selectedMilestone.key)[0].text,
          MilestoneType: selectedMilestone.key,
          MilestoneDate: selectedMilestoneDate,
          itemSelected:false
        }
         setMilestoneRecords([...milestoneRecords, newMilestone]);
         props.onSaveMilestone([...milestoneRecords, newMilestone]);
       } 
       else{ 
        milestoneRecords.forEach((x: any) => { 
          if (x.MilestoneType === selectedMilestone.key) { 
            x.MilestoneDate = selectedMilestoneDate;
          }
        }) 
        setMilestoneRecords([...milestoneRecords]);
        props.onSaveMilestone(milestoneRecords);
       }
       
    }
    setSelectedItem(undefined); 
    setShowMilestonePanel(false);
  }


  const onChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption, index?: number): void => {
   
    setOnSaveMilestone(false);// to reset 
    setSelectedItem(item);
    
    setSelectedMilestoneType({key:item?.key, text:item?.text}); 
    if (item?.text === 'Other'){ 
    }
  };


  const getColumns = (): CoherenceDataGridColumn<any>[] => {
    var advancedColumns: CoherenceDataGridColumn<any>[] = [
      {
        key: 'selectAll',
        name: 'Select All',
        fieldName: 'SelectAll',
        type: 'string',
        isResizable: false,
        minColumnWidth: 20,
        maxWidth: 20,
        ariaLabel: 'Select All',
        data: [],
        onRender: (item: any) => {
            return <Checkbox ariaLabel="Select row" onChange={(event) => handleSelectionCheckbox(event, item)}  checked={item.itemSelected} disabled = {(item.MilestoneTypeName == 'Target Completion Date')}/> 
        },
        onRenderHeader: (item: any) => {
            return <Checkbox ariaLabel="Select all rows" styles={{ root: { marginTop: '12px', marginBottom: '4px' } }} onChange={(event) => handleSelectAllCheckbox(event)} checked={isSelectedAll} />;
        },
      },
      {
        key: 'Actions',
        name: 'Edit',
        type: 'string',
        isResizable: false,
        minColumnWidth: 40,
        maxWidth: 40,
        data: [],
        onRender: (item: any) => { 
          function onClickGridAction(item: any) {
            let isRefActive=props.MilestonesTypeList.filter((x: any) => x.key === parseInt(item.MilestoneType));
              if(isRefActive.length==0){
                setIsMilestoneTypeInActive(true);  
              }
              else{
                setIsMilestoneTypeInActive(false);  
              }
              seteditColumnsDefaultValues(item);
              setIsEditMilestoneClicked(true);
              setShowMilestonePanel(true); 
              setSelectedMilestoneType({key:parseInt(item.MilestoneType), text:item.MilestoneTypeName});      
              setOriginalSelectedMilestoneDate(dayjs(item.MilestoneDate).format("MM/DD/YYYY"));
              setMilestoneDate(item.MilestoneDate); 
          }
          return <IconButton onClick={() => {onClickGridAction(item)}} disabled={item.MilestoneTypeName === 'Target Completion Date'?true:false}  style={{cursor:item.MilestoneTypeName === 'Target Completion Date'?'not-allowed':'pointer', paddingBottom: '8px'}} 
          iconProps={{ iconName: 'Edit'}}
      />  
        } 
      },
      {
        key: 'MilestoneTypeName',
        name: 'Milestone Name',
        fieldName: 'MilestoneTypeName',
        type: 'string',
        isResizable: true,
        ariaLabel: 'Milestone Type',
        minColumnWidth: 150,
        maxWidth: 180,
        data: [],
        iconClassName: "customSortIcon",
        onRender: (item: any) => {
          return <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
            {item.MilestoneTypeName}
          </span>
        }, 
      }, 
      {
        key: 'MilestoneDate',
        name: 'Milestone Date',
        fieldName: 'MilestoneDate',
        type: 'string',
        isResizable: true,
        ariaLabel: 'Milestone Date',
        minColumnWidth:200,
        maxWidth: 200,
        data: [],
        onRender: (item) => {
          return <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>{!isEmpty(item.MilestoneDate) ? dayjs(item.MilestoneDate).format('L'): "--"}
          </span>;
        },
      }
    ]; 
    return advancedColumns;
  };

  const generateMilestoneButton = (): any => {
    const _items: ICommandBarItemProps[] = [
      {
        key: 'Add',
        text: 'Add',
        iconProps: { iconName: 'Add'},
        onClick: () => {          
          setIsMilestoneTypeInActive(false);
          setIsEditMilestoneClicked(false);
          handleDateSelection(new Date());
          setSelectedMilestoneType('');
          setShowMilestonePanel(true);
          setOnSaveMilestone(false);// to reset 
        }
        },
        {
          key: 'ActionsDelete',
          name: 'Delete',
          type: 'actions',
          iconProps: {
            iconName: 'Delete',
            style: { color: "red" }
          },
          isResizable: false,
          menuActions: [],
          minColumnWidth: 55,
          maxWidth: 55,
          data: [],
          onClick: () => {

            const result = window.confirm('Are you sure you want to delete the selected milestone(s)?');
      
            if (result) {
              // Edit POR Delete
            
            if(pORId>0){
              let todeleterecords = {
                PORId:pORId,
                Ids: bulkEditSelectedData.map((item: any) => item.Id)
              }

              const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(todeleterecords),
                mode: 'cors'
              }
              setToastTitle("Delete Miletsone(s)");
              setToastMessage("Deleting the selected Milestone(s)");
              setToastType(CoherenceNotificationType.PENDING);
              setShowToast(true);
              DOMEApiHelper('PORDetails/DeleteMilestone', requestOptions)
              .then(res => {
                setIsSelectedAll(false); 
                setToastType(CoherenceNotificationType.SUCCESS);
                setToastMessage("Milestone(s) have been successfully deleted.");
                setTimeout(() => {
                  setShowToast(false);
                }, 3000);
                
                setBulkEditSelectedData([]); 
          
                if (res.Message==="Success") {
                  LoadMilestoneData(pORId);
                    
                }
              }).catch(res => {
                setToastMessage(res.toString());
                setToastType(CoherenceNotificationType.ERROR);
                setTimeout(() => {
                  setShowToast(false);
                }, 3000);
              });
            }
            else{
                // Create POR Delete 
                let notremoveDeletedContacts = milestoneRecords.filter((item: any) => item.itemSelected === false);
                setMilestoneRecords(notremoveDeletedContacts);  
                setIsSelectedAll(false);
                setBulkEditSelectedData([]);
            }
             
          }
                        
          },
          disabled: (bulkEditSelectedData.length > 0) ? false : true,
        }      
      
  ];
    return _items;
  }

  const disableSaveButton = (): boolean => {  
      if(isEditMilestoneClicked &&  editColumnsDefaultValues.MilestoneTypeName === 'Target Completion Date'){
        return true;
      }
      if(isEditMilestoneClicked ){
        return dayjs(selectedMilestoneDate).format("MM/DD/YYYY") != originalSelectedMilestoneDate?false:true; 
      }
      else{
        return  isEmpty(selectedMilestone) || isEmpty(selectedMilestoneDate)?true:false; 
      } 
  } 

  const generateMilestonePanel = (): any => {
    let tmpRenderObj: any[] = [];
    if(!isEditMilestoneClicked){
      tmpRenderObj.push(
        <>
          <div> 
            <Dropdown
              label={'Milestone Type'}
              placeholder="Select Milestone Type"
              required={true}
              options={props.MilestonesTypeList.filter((item:any) => !(milestoneRecords.some((record:any) => record.MilestoneTypeName === item.text) || item.text =="Target Completion Date"))}
              errorMessage={''}
              onChange={(
                event: React.FormEvent<HTMLDivElement>,
                option?: IDropdownOption<any> | undefined,
                index?: number | undefined
              ) => onChange(event, option)
                }
            />  
          </div>
          </>); 
    }
    else{
      tmpRenderObj.push(
        <><Label>Milestone Type</Label> <Label className="readonyLabel" disabled>{selectedMilestone? selectedMilestone.text:''}</Label></>
    );
    }
    tmpRenderObj.push(
      <> <Label>Milestone Date<span style={{paddingLeft:4, color:'#b74c41'}}>*</span></Label>  
       <DatePicker
                value={selectedMilestoneDate ? new Date(selectedMilestoneDate) : new Date()}                    
                minDate={new Date()}      
                placeholder={"Please select the Date"}
                onSelectDate={newDate => {  
                  handleDateSelection(newDate); }}
                formatDate={onFormatDate} 
              textField={{ errorMessage: "" }}
        ></DatePicker>
       <br></br>
      </>
  );
    tmpRenderObj.push(
        <> 
          <PrimaryButton   disabled={disableSaveButton() || isMilestoneTypeInActive}
           onClick={handleMilestoneSave}>{!isEditMilestoneClicked?'Add Milestone':'Save Milestone'}</PrimaryButton> 
        </>
      );
    return tmpRenderObj;
  }   

  //when in edit mode get the por id
const { Id } = useParams();
useEffect(() => {
  if (Id) { 
    setPORId(Id);    
  }
}, [Id]);

useEffect(() => {
  //load MilestonesTypeList from props
  if (props.MilestonesTypeList.length > 0 && pORId) {
    LoadMilestoneData(pORId);
 }
 }, [props.MilestonesTypeList]);
 
  const LoadMilestoneData = (PORID: string) => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }, 
      mode: 'cors'
    };
     setIsLoaded(true);
    DOMEApiHelper('PORDetails/GetMilestone?PORId='+PORID, requestOptions)
      .then(res => { 
        setIsLoaded(false);  
        setMilestoneRecords([
          ...res.map((item: any) => ({
            Id: item.Id,
            MilestoneDate:item.MilestoneDate,
            MilestoneType:item.MilestoneType,
            MilestoneTypeName:item.MilestoneTypeName,
            itemSelected:false
          })) 
        ]);  
      }).catch(res => { 
        setIsLoaded(false);
    });
  } 

return <div>
  {showToast && <ToastMessage showToast={showToast} type={toastType} message={toastMessage} title={toastTitle}  ></ToastMessage>}  
  <div style={{ display: 'flex', justifyContent: 'end' }}>
     <CommandBar
        items={[]}
        farItems={generateMilestoneButton()}
        ariaLabel="Milestone Buttons"
        primaryGroupAriaLabel="Milestone Buttons"
        farItemsGroupAriaLabel="Milestone Buttons"
        style={{ display: 'flex', justifyContent: 'start' }}
      />
      </div>
      <div style={{ minHeight: 255, height: 255, maxHeight: 255, overflowX: 'auto' }}> 
                  {!isLoaded ?<CoherenceDataGrid
                          listConfig={getColumns()}
                          data={milestoneRecords}
                          ariaLabelForSelectAllCheckbox={'select all checkbox'}
                          isScrollable={true}      
                          isSortable={false}
                          sortByDefault={false}
                          layoutMode={DetailsListLayoutMode.fixedColumns}
                          ariaLabel={"Milestone Data List"}
                        ></CoherenceDataGrid>
                        : <CoherenceLoading primaryText={""}></CoherenceLoading>} 
                </div> 
    
    <CoherencePanel
      title={"Milestone"}
      titleText={"Milestone"}
      isOpen={showMilestonePanel}
      onDismiss={handleOnPanelXClose}
      panelSize={CoherencePanelSize.small}
      closeButtonAriaLabel="Close"
    >
      {props.ServiceLineError? <><svg width="16" height="20" viewBox="0 -9 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12.0001 1.99805C17.5238 1.99805 22.0016 6.47589 22.0016 11.9996C22.0016 17.5233 17.5238 22.0011 12.0001 22.0011C6.47638 22.0011 1.99854 17.5233 1.99854 11.9996C1.99854 6.47589 6.47638 1.99805 12.0001 1.99805ZM11.9963 10.2486C11.4834 10.249 11.061 10.6353 11.0036 11.1327L10.9969 11.2493L11.0005 16.7509L11.0073 16.8675C11.0654 17.3648 11.4884 17.7506 12.0012 17.7502C12.514 17.7499 12.9364 17.3636 12.9939 16.8662L13.0005 16.7496L12.9969 11.248L12.9901 11.1314C12.932 10.6341 12.5091 10.2483 11.9963 10.2486ZM12.0005 6.49908C11.3093 6.49908 10.749 7.0594 10.749 7.7506C10.749 8.4418 11.3093 9.00212 12.0005 9.00212C12.6917 9.00212 13.2521 8.4418 13.2521 7.7506C13.2521 7.0594 12.6917 6.49908 12.0005 6.49908Z" fill="#212121" />
</svg> <span style={{color:"#a4262c"}}>A service line selection is required to proceed.</span> </> : null}
      {showMilestonePanel && generateMilestonePanel()}
    </CoherencePanel>

  </div>
};
export default Milestones; 
