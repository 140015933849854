import React from "react";  
import { UnAuthorizedStyles } from "./UnAuthorized.styles";
import { Link } from "react-router-dom";

export const UnAuthorized: React.FunctionComponent = () => { 
const {unauthorizedHeader} = UnAuthorizedStyles;
return <div>
  <h2 className={unauthorizedHeader}>Access Denied</h2>
  <h3>You don't have permission to access this page. </h3>
  <span>To submit an access request, please   <Link
      aria-label="Click Here"
      onClick={() => {
        window.open(process.env.REACT_APP_CoreIdentityLink, "_blank");
      } }
      style={{ color: "#0078d4", height: '22px' }} to={""}>{"click here"}</Link> and select the appropriate role: 

  </span>
  <ul> 
    <li>To view/edit the Non-Managed data, choose the NonManaged role option.</li>
    <li>To view/edit the Managed Data (POR Items, Create POR, Scope Definition, Intake Management), choose the Managed role option.</li>
    <li>To view/create the OLS Offer ID, choose the OLS Offer ID Generation role option.</li>
    <li>To view/edit the Finance Data Audit, choose the Data Audit role option.</li> 
  </ul>
</div>;  
};
export default UnAuthorized;