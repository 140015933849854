import { CoherenceNotificationType, CoherenceToast, CoherenceToastItem } from "@coherence-design-system/controls";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useState } from "react";

interface ToastMessageProps {
    type: CoherenceNotificationType;
    message: string;
    title: string;
    showToast: boolean;
}
const ToastMessage = ({ type, message, title, showToast }: ToastMessageProps) => {
    const [showToastPanel, setShowToastPanel] = useState<boolean>(false);

    const toastItem: CoherenceToastItem = {
        itemKey: message,
        timeDelivered: dayjs(),
        subject: { header: title },
        messageBodyText: message,
        notificationActions: []
    };

    useEffect(() => {
        setShowToastPanel(showToast);
    }, [showToast]);  

    const onDismiss = () => setShowToastPanel(false);

    return (
        <div style={{
            position: 'fixed', top: 48, right: 24, zIndex: 1111111,
            display: 'flex',
            flexDirection: 'column',
            gap: '12px'
        }}
        >
        {showToastPanel   &&     <CoherenceToast 
                item={toastItem}
                onDismiss={onDismiss}
                type={type}
                toastDurationSec = {120}
            /> }
        </div>
    );
};

export default ToastMessage;
