import { IStyle, mergeStyleSets } from "@fluentui/react"; 

export const getAppStyles = (isNavCollapsed: boolean) =>
  mergeStyleSets({
    root: { 
      backgroundColor:'#faf9f8'
    } as IStyle,

    mainContainer: {
      marginLeft: isNavCollapsed ? "48px !important" : "228px !important",
      marginRight: "0",
      paddingLeft:10,
      paddingRight:10,
      transition: '0.3s'
    } as IStyle,
    footerStyle: {
      marginTop: "5px",
      marginRight: "0",
      transition: '0.3s',
      padding: 10,
      backgroundColor: 'white',
      borderTop: '1px solid #e1e1e1',
      position: "absolute",
      zIndex: 10000,
      width: '100%', // Ensures the footer width is responsive and within the viewport
      boxSizing: 'border-box', // Ensures padding and borders are included within the width
    } as IStyle,
    stickyHeader:{
      position: 'sticky',
      top: '0',
      width: '100%',
      zIndex: '100'
    }
  });