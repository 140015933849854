import React, {  useRef , useState, useEffect } from "react";
import { DOMEApiHelper,calculateTimeAgo, isEmpty, onFormatDate } from "../../../../../utils/DOMEApiHelper";
import { Stack, TextField, IStackStyles, IStackProps, DatePicker, Breadcrumb, Dropdown, PrimaryButton, DefaultButton, IDropdownOption, Label, Callout, Link, DetailsListLayoutMode, CommandBar, ICommandBarItemProps, Checkbox, NormalPeoplePicker, IActivityItemProps, mergeStyleSets, ActivityItem, Icon, AutoScroll } from "@fluentui/react";
import { CoherenceDataGrid, CoherenceDataGridColumn, CoherenceLoading, CoherenceNotificationType, CoherencePanel, CoherencePanelSize } from "@coherence-design-system/controls";
import { useLocation } from "react-router";
import type { EditorPlugin } from 'roosterjs';
import { CustomRTEPlugin } from "../../../../../shared/CustomRTEPlugin/CustomRTEPlugin";
import { GetUserRole } from "../../../../../utils/GetUserRole";
import { RichTextEditor } from "@coherence-design-system/rich-text-editor";
import { Card, CardStandardHeader } from "@coherence-design-system/card";

export const Discussions: React.FunctionComponent = () => {  
  const [userRole, setUserRole] = useState(GetUserRole());
  const [pORId, setPORId] = useState<any>();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [showDiscussionControl, setShowDiscussionControl] = useState<boolean>(true);
  const [discussionRecords, setDiscussionRecords] = useState<any[]>([]);
  const [selectedDescription, setSelectedDescription] = useState<any>();
  //By default discussion type in this file
  const [defaultDiscussionType, setDefaultDiscussionType] = useState<any>("PORDetails");
  const defaultUserName = sessionStorage.getItem("localAccountName");
  const defaultUserID = sessionStorage.getItem("localAccountId");
  const [toastType, setToastType] = useState<CoherenceNotificationType>(CoherenceNotificationType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastTitle, setToastTitle] = useState<string>("");
  const [showToast, setShowToast] = React.useState<boolean>(false);
  const [isDisabledSaveButton, setIsDisabledSaveButton] = useState<boolean>(false);  
  const stackTokens = { childrenGap: 50 };
  const stackStyles: Partial<IStackStyles> = { root: { whiteSpace: 'nowrap' } };
  const columnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: '100%' } },
  };
  const location = useLocation();
  const LoadDiscussionData = (PORID: string) => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    };
    setIsLoaded(true);
    DOMEApiHelper('PORDetails/GetDiscussionDetails?DiscussionId=' + PORID + '&DiscussionType='+ defaultDiscussionType, requestOptions)
      .then(res => {
        setIsLoaded(false);
        
        let result = [
          ...res.map((item: any) => ({
            Id: item.Id,
            Discussion: item.Discussion,
            CreatedByUserName: item.CreatedByUserName,
            CreatedBy: item.CreatedBy,
            CreatedDate: item.CreatedDate
          }))
        ]
        //sort result by created date descending order
        result= result.sort((a, b) => new Date(b.CreatedDate).getTime() - new Date(a.CreatedDate).getTime());
          setDiscussionRecords(result);

      }).catch(res => {
        setIsLoaded(false);
      });
  }
  
    //RichTextField
    const handleRichTextChange = (value: string | undefined): void => {
        setSelectedDescription(value);  
       
     }
   //plugin code start 
   const getPlugins = () => {
      let plugins: EditorPlugin[] = [];
      plugins.push(
        new CustomRTEPlugin((content:any) => {
        })
      );
      return plugins;
   };
    //plugin code end 

  const generateDiscussionControls = (): any => {
    let tmpRenderObj: any[] = [];
   if(showDiscussionControl){
     tmpRenderObj.push(
          <div
          style={{
            height: '180px',
           marginTop: '-10px'
          }}
        >
          <RichTextEditor
            characterMax={4000}
            label={""}         
            value={selectedDescription}
            onBlur={() => {}}
            onChange={handleRichTextChange}
            onFocus={() => {}}
            onInvalid={() => {}}
            onValid={() => {}}
            placeholder="Add your comments"
            resizable={true}
            plugins={getPlugins()}
          />
        </div>
      );
     }
    return tmpRenderObj;
  }
 
  // for to remove html tags while displaying in Comments
  const renderHTML = (escapedHTML: string) => 
        React.createElement("div", { dangerouslySetInnerHTML: { __html: escapedHTML } });

  useEffect(() => { 
    const routerName = location.pathname.split('/')[1];
    if (routerName === 'CreatePOR') {
      setIsEditMode(false);
    } else if (routerName === 'PORDetails') {
      const idStartIndex = window.location.href.lastIndexOf('/');
      const id = idStartIndex !== -1 ? window.location.href.substring(idStartIndex + 1) : null; 
      if (id) {
        LoadDiscussionData(id);
        setIsEditMode(true);
        setPORId(parseInt(id));
      }
    }
  }, [location]);


  const onSave = async () => {
    setIsDisabledSaveButton(true);
    if(isEmpty(selectedDescription)){ 
      return ;
    }
  
    let discussionData = {  
      DiscussionId: pORId,
      DiscussionType: defaultDiscussionType,
      Discussion: selectedDescription,
      CreatedBy: defaultUserID   
    }
    let discussionDataList : any =[];
     discussionDataList.push(discussionData);  
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(discussionDataList),
        mode: "cors",
      };
      setToastTitle("Add Discussion"); 
      setToastMessage("Adding Discussion is in progress");
      setToastType(CoherenceNotificationType.PENDING);
      setShowToast(true);
      DOMEApiHelper('PORDetails/SaveDiscussionsDetails', requestOptions)
        .then(res => {
          setToastType(CoherenceNotificationType.SUCCESS);
          setToastMessage("Discussion has been updated successfully.");
          setTimeout(() => {
            setShowToast(false);
          }, 3000);
          setIsDisabledSaveButton(false);
          
          if (res.Message === "Success") {
            LoadDiscussionData(pORId);
            setSelectedDescription("");
           
          }
        }).catch(res => {
          setToastMessage(res.toString());
          setIsDisabledSaveButton(false);
          setToastType(CoherenceNotificationType.ERROR);
          setTimeout(() => {
            setShowToast(false);
          }, 3000);
        });
    }

  const onCancel = () => { 
   //Make description empty when click on cancel button
    setSelectedDescription("");
    setShowDiscussionControl(false);
    setTimeout(() => {
      setShowDiscussionControl(true);
    }, 10);
    }

  const disableSaveButton = (): boolean => { 
    return isEmpty(selectedDescription) ? true : false;
  } 
                                       
   const classNames = mergeStyleSets({
        commentsText: {
          marginTop: '10px',
        },
        nameText: {
          fontWeight: 'bold',
        },
        
      });


  const activityItemExamples: (IActivityItemProps & { key: string | number })[] = [];
  discussionRecords.forEach((item:any) => 
  { 
    activityItemExamples.push(
    { 
      key: item.Id, 
      activityDescription: [ <Link key={1} className={classNames.nameText}> {item.CreatedByUserName} </Link>,
                           <span key={2}> {"commented"} </span>, ],
      activityPersonas: [{ imageUrl: item.CreatedByUserName }],
      //activityPersonas:[{ imageInitials: item.CreatedByUserName , text: item.CreatedByUserName }],
      comments: renderHTML(item.Discussion), 
      timeStamp: calculateTimeAgo(item.CreatedDate)
    });
    });
  


return <div style={{ minHeight: 575, height: 575, maxHeight: 575 }}>  
      <Card >
           {!isLoaded ? <Stack horizontal tokens={stackTokens} styles={stackStyles}>
             <Stack {...columnProps}>
               {generateDiscussionControls()}
               <Stack horizontal tokens={{ childrenGap: 10 }}>
                <PrimaryButton text="Save"  disabled={disableSaveButton() || isDisabledSaveButton} onClick={onSave} />
                 <DefaultButton text="Cancel" onClick={onCancel} />
              </Stack>
             </Stack>
          </Stack>
             : <div style={{ minHeight: 200, height: 200, maxHeight: 200 }}>
            <CoherenceLoading primaryText={""}></CoherenceLoading>
            </div>}

          <div>
            {!isLoaded ?
              <div style={{overflowY: 'auto' ,height: '300px',display:'float',scrollBehavior: 'smooth'}}> 
                {activityItemExamples.map((item:any) =>  
                
                ( <ActivityItem {...item} className={classNames.commentsText} /> ))} 
                </div> 
                : ""
            }  
          </div>
                     
      </Card>
       
  </div>
};
export default Discussions;
