import { registerIcons } from "@fluentui/react"; 
import { CloudFlow24Regular } from "@fluentui/react-icons";
import React from "react";

let iconsRegistered = false;

export const registerIconsOnce = () => {
  if (!iconsRegistered) {
    registerIcons({
      icons: {
        CloudFlow:  <CloudFlow24Regular  style={{ paddingTop:'10px',height:"20px",width:"20px",fontSize:"14px" }} /> 
        // ... other icons
      }
    });
    iconsRegistered = true;
  }
};