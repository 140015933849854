import { IStyle, mergeStyleSets } from "@fluentui/react";

export const ScopeDefinitionStyles = mergeStyleSets({

    patternAffectedTableStyle:{
        display: 'block',
        overflow: 'auto',
        width: '100%',
        paddingTop:"4px",
        paddingLeft:"20px"
        }
});

export const UnAuthorizedStyles = mergeStyleSets({
    unauthorizedHeader: {  
      color: 'red'   
    }
    
  });
  