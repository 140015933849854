
import React from 'react';
import { ScreenWidthMinXLarge } from '@fluentui/react';

const MOBILE_BREAKPOINT = ScreenWidthMinXLarge;

export const useIsMobile = (breakpoint = MOBILE_BREAKPOINT) => {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < breakpoint);

  const handleResize = React.useCallback(() => {
    setIsMobile(window.innerWidth < breakpoint);
  }, []);

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // TODO: make debounce
  React.useEffect(() => {
    setIsMobile(window.innerWidth < breakpoint);
  }, [window.innerWidth]);

  return isMobile;
};
