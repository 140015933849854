import { RedirectRequest } from "@azure/msal-browser";
export const clientId=process.env.REACT_APP_CLIENT_ID!==undefined?process.env.REACT_APP_CLIENT_ID:"";
export const tenantId=process.env.REACT_APP_TENANT_ID!==undefined?process.env.REACT_APP_TENANT_ID:"";
export const authority=process.env.REACT_APP_AUTHORITY!==undefined?process.env.REACT_APP_AUTHORITY:""; 

// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId:  clientId,
        tenantId: tenantId,
        authority: authority,
        redirectUri: "/",
        postLogoutRedirectUri: "/"
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
    scopes: ["User.Read"]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me"
};

export const appRoles = {
    OLSOfferIDGeneration:"OLSOfferIDGeneration",
    Admin:"Admin",
    Accessibility: "Accessibility",
    Managed: "Managed",
    NonManaged: "NonManaged",
    AuditData: "AuditData"
}

export type IdTokenClaims = {
    aud: string;
    exp: number;
    roles: string[];    
  };