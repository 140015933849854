import { CoherenceModal } from "@coherence-design-system/controls";
import React from "react";

export const SessionTimeout: React.FunctionComponent = () => { 
    return (
        <div>
            <CoherenceModal
                height="responsive"
                isOpen
                modalWidth="medium"
                onDismiss={function ReloadPage(){
                    window.location.href = window.location.origin;
                }}
                title="Session Timeout">
                <h3>Your session has expired.</h3>
                <a href='/'>Please click here to continue.</a> 
            </CoherenceModal>
        </div>  
    );
};
export default SessionTimeout;