 
import { Dropdown, DropdownMenuItemType, IDropdownOption, IDropdownProps, SearchBox } from "@fluentui/react";
import React from "react";   

export const SearchableDropdown: React.FunctionComponent<IDropdownProps> = (props) => {
    const [searchText, setSearchText] = React.useState<string>('');  
 
    function renderOption(option?: IDropdownOption): JSX.Element {
      return (option?.itemType === DropdownMenuItemType.Header && option.key === "FilterHeader") ?
        <SearchBox id="searchTextId" onChange={(ev:any, newValue:any) => setSearchText(newValue)} underlined={true} placeholder="Search options"/> : <>{option?.text}</>;
    }

    const onOpenOptionList = () => {
      setTimeout(() => { 
          document.getElementById("searchTextId")?.focus();    
      }, 100);
    } 

   
    return (
       <Dropdown  
        {...props}
        panelProps={{
          onOpen: onOpenOptionList
        }} 
        options={[
          { key: 'FilterHeader', text: '-', itemType: DropdownMenuItemType.Header },
          { key: 'divider_filterHeader', text: '-', itemType: DropdownMenuItemType.Divider },
          ...props.options.map(option => !option.disabled && option.text.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ?
            option : { ...option, hidden: true }
          ),
        ]} 
        onRenderOption={renderOption}
        onDismiss={() => setSearchText('')} 
        calloutProps={{
          onLayerMounted: onOpenOptionList,
          calloutWidth: undefined
        }} 
      /> 
    );
  
  };