import { CacheLookupPolicy } from "@azure/msal-browser";
import { loginRequest, IdTokenClaims } from "../authConfig";
import { msalInstance } from "../index";
import { format } from 'date-fns';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

export async function DOMEApiHelper(apiurl: string, apioptions: any) {
    apiurl = process.env.REACT_APP_API_HOST_URL + apiurl;

    const account = msalInstance.getActiveAccount(); // Get current active account

    if (!account) { 
        // Call the function to clear local storage
        clearLocalStorageExceptFilterAndSort();
        sessionStorage.clear();
        window.location.href = window.location.origin + "/SessionTimeout";
        return "";
    }

    const response = await acquireToken(account); // fetch the token
    const headers = createHeaders(response.accessToken, apiurl); // append the idToken to header
    apioptions.headers = headers;

    return fetchAndHandleResponse(apiurl, apioptions); // API call
}
 
async function acquireToken(account: any) {
  const tokenRequest = {
    ...loginRequest,
    account: account,
    scopes: [process.env.REACT_APP_API_SCOPE] as string[],
    forceRefresh: !isValidToken(account),
    cacheLookupPolicy: CacheLookupPolicy.Default
  };
  return await msalInstance.acquireTokenSilent(tokenRequest);
}

function createHeaders(idToken: string, apiurl: string) {
    const headers = new Headers();
    const bearer = `Bearer ${idToken}`;
    headers.append("Authorization", bearer);
    if (!apiurl.includes("PORDetails/CreatePORItem") && !apiurl.includes("PORDetails/SaveAttachment")  && !apiurl.includes("IntakeManagement/CreateIntakePORItem") ) {
        headers.append("Content-Type", "application/json");
    }
    return headers;
}

// for to display datetime to time ago
export const calculateTimeAgo = (inputDate: any) => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const currentDate=dayjs();
    const inputDateToLocalDate = dayjs.utc(inputDate).tz(dayjs.tz.guess());  
    const diffMinutes = currentDate.diff(dayjs(inputDateToLocalDate), 'minute');
   
    if (diffMinutes < 1) {
      return 'just now';
    } else if (diffMinutes < 60) {
      return `${diffMinutes} minute${diffMinutes > 1 ? 's' : ''} ago`;
    } else if (diffMinutes < 1440) {
      const diffHours = Math.floor(diffMinutes / 60);
      return `${diffHours} hour${diffHours > 1 ? 's' : ''} ago`;
    } else if (diffMinutes < 10080) { // Less than a week (7 days)
      const diffDays = Math.floor(diffMinutes / 1440);
      return `${diffDays} day${diffDays > 1 ? 's' : ''} ago`;
    } else {
      return inputDateToLocalDate.format('D MMM YYYY [at] HH:mm'); // Show date, month, and year
    }
  }

//Check if idToken has expired
function isValidToken(account: any) {
    const idTokenClaims = account.idTokenClaims as IdTokenClaims;
    const expirationDate = new Date(idTokenClaims["exp"] * 1000 - 1 * 60000);
    return expirationDate > new Date();
}

async function fetchAndHandleResponse(apiurl: string, apioptions: any) {
    return fetch(apiurl, apioptions)
        .then((response: any) => {
            if (response.status !== 200) {
                return response.json().then((errorData: any) => {
                    throw new Error(`${JSON.stringify(errorData.message ? errorData.message : errorData.errors)}`);
                });
            } else {
                return response.json();
            }
        })
        .catch(error => {
           
            if (error instanceof Response) {
                // if the error is a Response object, read the error message from the response
                return error.json().then(errorMessage => {
                    throw new Error(`${errorMessage.message}`);
                });
            } else {
                throw error;
            }
        });
}


export const isEmpty = (value: any) => {
    let isEmpty = false;
    if (value === undefined || value === null || value === '') {
        isEmpty = true;
    }
    else if (value != undefined && value != null && typeof value === 'string' && value.trim() === '') {
      isEmpty = true;
    } else if (Array.isArray(value) && value.length === 0) {
        isEmpty = true;
    } else if (value instanceof Date && isNaN(value.getTime())) {
        isEmpty = true;
    } else if (typeof value === 'object' && !(value instanceof Date) && Object.keys(value).length === 0) {
        isEmpty = true;
    }
    else if (value === 0) {
        isEmpty = true;
    }
    return isEmpty;
}; 


  export const  deepEqual= (obj1: any,obj2:any) => {
    // Compare objects deeply, including nested arrays and objects
  
    if (obj1 === obj2) {
      return true;
    }
  
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
      return false;
    }
  
    const obj1Keys = Object.keys(obj1);
    const obj2Keys = Object.keys(obj2);
  
    if (obj1Keys.length !== obj2Keys.length) {
      return false;
    }
  
    for (const key of obj1Keys) {
      if (!obj2.hasOwnProperty(key) || !deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }
  
    return true;
  }

export const onFormatDate = (_date?: any) => {
    return format(_date, 'MM/dd/yyyy')
}; 
 
    export const convertToCsv = (jsonData: any,fileName:any) => {
      const csvRows = [];      
      // Extracting unique keys from JSON data
      const headers = Array.from(
        new Set(jsonData.flatMap((obj: any) => Object.keys(obj)))
      );
    
      csvRows.push(headers.join(','));
    
      for (const row of jsonData) {
        const values = headers.map((header: any) => {
          let value = row[header];
          // If value is undefined, replace it with an empty string
          if (value === undefined) {
            value = '';
          }
          let escaped = ('' + value).split('').map(char => {
            if (char === '"') {
                return '\\"'; // Replace double quotes with escaped double quotes
            } else {
                return char; // Keep other characters unchanged
            }
        }).join('');
          return `"${escaped}"`;
        });
    
        csvRows.push(values.join(','));
      }

  const csvData = csvRows.join('\n');
  const blob = new Blob([csvData], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName+'.csv';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}; 

//store key and value in localstorage
export function storeDataInLocalStorage(key:any, value:any) {
  localStorage.setItem(key, JSON.stringify(value));
}

//retrieve value by key from local storage
export function getDataFromLocalStorage(key:any) {
  const storedData = localStorage.getItem(key);
  if (storedData) {
      return JSON.parse(storedData); // Convert back to JavaScript object
  }
  return null; // Return null if no data is found
}


// Function to clear local storage except keys containing "filter" and "sort"
export function clearLocalStorageExceptFilterAndSort() {
    let i = 0;
    while (i < localStorage.length) {
      const key = localStorage.key(i);
      
      if (key !== null && !key.toLowerCase().includes("filter") && !key.toLowerCase().includes("sort")) {
        localStorage.removeItem(key);
      } else {
        i++;
      }
    }
  } 