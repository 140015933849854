import React, {useEffect, useState} from "react"; 
import { Card, CardStandardHeader, CoherenceCardBasicButton } from "@coherence-design-system/card";
import "../../index.css";

interface IProps {
  title: string;
  children: any;
  isExpandDefault:any;
  onAccordianChange?:any;
} 

const Accordion = (props: IProps) => { 
  const [isActive, setIsActive] = useState(false);
   
  useEffect(() => {  
    setIsActive(props.isExpandDefault?true:false);
  }, []);

  useEffect(() => {  
    setIsActive(props.isExpandDefault?true:false);
  }, [props.isExpandDefault]);
  
  const onCardClick = () => {
    setIsActive(!isActive);  
   if (props.onAccordianChange) {
      props.onAccordianChange(isActive, props.title);
   }
  };

  return ( 
    <div style={{width:"auto", height: isActive? 'auto':"39px",paddingBottom:20 }}>
      <Card header={<CardStandardHeader cardTitle={props.title}  contextProps={{customButton: <CoherenceCardBasicButton iconName={isActive?'ChevronUpMed':'ChevronDownMed'} linkProps={{'aria-label': isActive?'Expand button':'Collapse button', onClick:onCardClick}} styles={{icon: [{color: '#323130', margin: '0px 4px', textAlign: 'center'}], root: [{height: '40px', padding: '0px', selectors: {':active': {background: '#edebe9', color: '#323130', textDecoration: 'none'}, ':active:hover': {background: '#edebe9', color: '#323130', textDecoration: 'none'}, ':hover': {background: '#f3f2f1', color: '#323130', textDecoration: 'none'}}, width: '40px'}]}}/>}}/>}>
        <div style={{ display:isActive ? 'block' : 'none' }}>
        {props.children} 
        </div>
      </Card> 
    </div> 
  );
};  
  
export default Accordion;
