import React from 'react';
import { Stack, Link, Text, Separator, IStackStyles, ITextStyles, ILinkStyles } from '@fluentui/react';
 
const textStyles: ITextStyles = {
  root: {
    marginRight: 10,
    fontWeight: 'bold',
    color: 'black',
  },
};
const linkStyles: ILinkStyles = {
  root: {
    color: 'rgb(0, 90, 158)', // White links for contrast
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'underline',
    },
  },
};

const AppFooter: React.FC = () => {
  return (
    <Stack
      horizontal
      horizontalAlign="space-between"
      verticalAlign="center" 
    >
      <Text variant="small" styles={textStyles}>&copy; 2024 Microsoft</Text>
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <Link href="https://go.microsoft.com/fwlink/?LinkId=518021" styles={linkStyles} target="_blank">Microsoft Data Privacy Notice</Link>
        {/* <Separator vertical />  */}
      </Stack>
    </Stack>
  );
};

export default AppFooter;
