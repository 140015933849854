import {
  DefaultPalette,
  FontSizes,
  FontWeights,
  IStyle,
  mergeStyleSets,
} from "@fluentui/react";

export const panelStyles = mergeStyleSets({ 
  panelLink: {
    margin: "8px 0",
    display: "block",
    fontSize: FontSizes.size14,
    fontWeight: FontWeights.semibold,
  } as IStyle,
  panelList: {
    listStyle: "none",
    paddingLeft: "0",
  } as IStyle,
});

export const settingsStyles = mergeStyleSets({
  root: {
    marginLeft: "0",
    marginRight: "0",
  } as IStyle,
});

export const profilePanelStyles = {
  root: {
    color: DefaultPalette.themeDark,
    fontSize: FontSizes.size14,
    fontWeight: FontWeights.semibold,
  },
  rootHovered: {
    color: DefaultPalette.themeDarker,
    fontSize: FontSizes.size14,
    fontWeight: FontWeights.semibold,
  },
  rootFocused: {
    color: DefaultPalette.themeDarker,
    fontSize: FontSizes.size14,
    fontWeight: FontWeights.semibold,
  },
  rootPressed: {
    color: DefaultPalette.themeDarker,
    fontSize: FontSizes.size14,
    fontWeight: FontWeights.semibold,
  },
  icon: {
    color: DefaultPalette.themeDark,
    fontSize: FontSizes.size14,
    fontWeight: FontWeights.semibold,
  },
  iconHovered: {
    color: DefaultPalette.themeDarker,
    fontSize: FontSizes.size14,
    fontWeight: FontWeights.semibold,
  },
  iconFocused: {
    color: DefaultPalette.themeDarker,
    fontSize: FontSizes.size14,
    fontWeight: FontWeights.semibold,
  },
  iconPressed: {
    color: DefaultPalette.themeDarker,
    fontSize: FontSizes.size14,
    fontWeight: FontWeights.semibold,
  },
};
