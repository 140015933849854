import React from "react";   
import { Link } from "react-router-dom";
import { UnAuthorizedStyles } from "../UnAuthorized/UnAuthorized.styles";

export const UnderDevelopment: React.FunctionComponent = () => { 
const {unauthorizedHeader} = UnAuthorizedStyles;
return <div>
   <h2 className={unauthorizedHeader}>Work In Progress</h2>
  <h3>We're presently engaged in migration activities and anticipate being back online by 3 PM PST on April 26th. </h3>  
</div>;  
};
export default UnderDevelopment;